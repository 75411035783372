@use '@angular/material' as mat;
@use '@angular/cdk/overlay' as cdko;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mat-mdc-dialog-container .mat-mdc-dialog-content {
        padding: 0;
        color: #000000de;

        @if $is-dark {
            background-color: mat.get-color-from-palette($primary, 700);
            color: #fff;
        }
    }

    .mat-mdc-dialog-surface {
        border-radius: 32px !important;
        padding: 24px 40px;
    }

    .mat-mdc-dialog-title {
        padding: 0;
        margin-bottom: 20px;
    }

    .mona-dialog__backdrop--transparent {
        background-color: rgba(0, 0, 0, 0.12);
    }

    .mat-mdc-dialog-container .mona-dialog {
        padding: 32px;
        margin-bottom: 100px;

        &__illustration {
            width: 300px;
            margin: 16px 48px 24px 16px;
            padding-top: 16px;
        }

        &__title {
            font-size: 35px;
            font-weight: 300;
            margin-bottom: 23px;
            display: flex;
            justify-content: space-between;
            letter-spacing: normal;
            color: mat.get-color-from-palette($primary);

            @if $is-dark {
                color: #fff;
            }

            &::before {
                display: none;
            }
        }

        &__close-icon {
            opacity: 0.54;
            position: absolute;
            top: 0;
            right: 0;
        }

        &__description {
            font-size: 18px;
            color: #000;
            letter-spacing: normal;

            @if $is-dark {
                color: #fff;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 58px 42px 0 42px;
            margin: 0 -24px;
        }

        &__details {
            background: rgba(0, 0, 0, 0.08);
            border: 1px solid rgba(0, 0, 0, 0.2);
        }

        &__actions {
            display: flex;
            justify-content: center;
            padding-bottom: 12px;

            button {
                margin-right: 16px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}

.cdk-overlay-container {
    .mona-lock-screen--opened & {
        z-index: cdko.$overlay-z-index + 1;
    }

    .mona-dialog {
        .mona-keyboard--opened & {
            margin-bottom: $mona-keyboard-height - 24;
        }
    }
}
