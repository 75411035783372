@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-basic-care-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);
    $addIconColor: if($is-dark, rgba(255, 255, 255, 0.08), rgba(0, 0, 0, 0.32));

    $rowHeight: 50px;
    $groupRowHeight: 58px;

    .app-basic-care {
        .ui-table {
            &-tr {
                &:not(.ui-table-tr--group):not(.ui-table-tr--header):not(.ui-table-tr--top-header) {
                    height: $rowHeight;
                }

                &--group {
                    height: $groupRowHeight;
                }
            }

            &-td {
                &.mat-value-cell:first-of-type {
                    padding: 0 !important;
                }
                .mat-column-value {
                    min-height: $rowHeight - 2px;
                }

                &--active ~ * {
                    pointer-events: none;
                    .mat-column-value__icon-add-suggestion {
                        opacity: 0.4;
                    }
                    .mat-column-value__icon-add {
                        display: none;
                    }
                }
            }
        }

        &__menu-item {
            &--edit {
                &:after {
                    background-color: mat.get-color-from-palette($primary);

                    @if $is-dark {
                        background-color: mat.get-color-from-palette($primary, A400);
                    }
                }
            }

            &--remove {
                &:after {
                    background-color: mat.get-color-from-palette($warn);
                }
            }
        }

        &__add-menu {
            max-height: 300px;
        }

        // Value label column
        .mat-column-name {
            position: relative;
            color: mat.get-color-from-palette($foreground, text);
            font-size: 16px;

            &.ui-table-td {
                text-align: left;
            }
            &.ui-table-td--group {
                font-weight: 500;
            }

            &.mat-table-sticky {
                position: sticky;
            }

            .mat-mdc-form-field-hint {
                font-size: 14px;
                font-weight: 400;
            }
        }

        .mat-column-value {
            &--has-value {
                color: mat.get-color-from-palette($success, 500);
            }

            &--changed {
                color: $changes-color;
                stroke: $changes-color;
            }

            &--removed {
                cursor: default;
                text-decoration: line-through;
                pointer-events: none;
                color: mat.get-color-from-palette($accent, 500);
            }

            &__icon-add,
            &__icon-add-suggestion {
                &.mat-icon {
                    width: 22px;
                    height: 22px;
                    font-size: 22px;
                    line-height: 22px;
                }
            }

            &__icon-removed,
            &__icon-no-suggestion {
                &.mat-icon {
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    line-height: 16px;
                    vertical-align: text-top;
                    margin-left: 4px;
                }
            }

            &__icon-add {
                color: $addIconColor;
                // display: none;
            }
            &__icon-no-suggestion {
                color: $changes-color;
            }
        }
    }
}
