@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-app-bar-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $white: map-get($theme, white);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .ui-app-bar {
        &--with-elevation {
            @include mat.elevation(4);
        }
    }

    .ui-app-bar-content {
        background-color: mat.get-color-from-palette($background, card);

        @if $is-dark {
            color: rgba(255, 255, 255, 0.72);
        } @else {
            color: mat.get-color-from-palette($foreground);
        }

        // @if $is-dark {
        //     background-color: rgba(255, 255, 255, 0.5);
        // } @else {
        //     background-color: mat.get-color-from-palette($primary, 50);
        // }
    }
}
