/*
Import the local variables file first to set the correct variables, see:
  - https://mona.nbb.be/showcase/latest/styleguide/typography
  - https://mona.nbb.be/showcase/latest/styleguide/colors
*/
@use 'variables';
@use 'sass:map';
@use 'overrides';

// Import material themes
@use '@angular/material' as mat;
@use 'theming/light' as light;
@use 'theming/dark' as dark;
@use 'components';

// Import Mona libs themes
@use 'mona/auth' as auth;
@use 'mona/api' as api;
@use 'device';
@use 'pdms';
@use 'telemedicine';
@use 'voice';

// Import main Mona theme
@import 'mona-ui';

// Generates styles for all systems configured in the theme. In this case, color styles
// and default density styles are generated. Density is in themes by default.
@include mat.all-legacy-component-themes(light.$mona-theme);
@include mat.all-component-themes(light.$mona-theme);

@include mat.checkbox-density(-5);

.theme-mona-dark {
    // This mixin only generates the color styles now.
    @include mat.all-component-colors(dark.$mona-theme);

    // Plus custom mixins for customizations
    @include components.ui-components-theme(dark.$mona-theme);
    /* ---------------------------------------------------------------------- */
    /*                             API                                       */
    /* ---------------------------------------------------------------------- */
    @include api.mona-diagnostics-dialog-theme(dark.$mona-theme);
    @include api.mona-server-url-dialog-theme(dark.$mona-theme);
    /* ---------------------------------------------------------------------- */
    /*                             AUTH                                       */
    /* ---------------------------------------------------------------------- */
    @include auth.mona-lock-screen-theme(dark.$mona-theme);
    @include auth.mona-signin-theme(dark.$mona-theme);
    /* ---------------------------------------------------------------------- */
    /*                             DEVICE                                     */
    /* ---------------------------------------------------------------------- */
    @include device.mona-health-check-theme(dark.$mona-theme);
    @include device.mona-step-actions-portal-theme(dark.$mona-theme);
    @include device.mona-wifi-connect-dialog-theme(dark.$mona-theme);
    @include device.mona-device-setup-theme(dark.$mona-theme);
    /* ---------------------------------------------------------------------- */
    /*                             PDMS                                       */
    /* ---------------------------------------------------------------------- */
    @include pdms.mona-patient-admission-search-theme(dark.$mona-theme);
    @include pdms.mona-notifications-theme(dark.$mona-theme);
    @include pdms.mona-medication-theme(dark.$mona-theme);
    @include pdms.mona-task-list-theme(dark.$mona-theme);
    @include pdms.mona-wards-theme(dark.$mona-theme);
    @include pdms.mona-encounter-theme(dark.$mona-theme);
    @include pdms.mona-settings-theme(dark.$mona-theme);
    @include pdms.mona-feature-prescription-sets-theme(dark.$mona-theme);
    @include pdms.mona-patient-management-theme(dark.$mona-theme);
    @include pdms.pdms-shared-theme(dark.$mona-theme);
    @include pdms.mona-workflows-theme(dark.$mona-theme);
    @include pdms.mona-workflows-manage-theme(dark.$mona-theme);
    @include pdms.mona-treatment-interventions-theme(light.$mona-theme);
    /* ---------------------------------------------------------------------- */
    /*                             TELEMEDICINE                               */
    /* ---------------------------------------------------------------------- */
    @include telemedicine.mona-camera-dialog-theme(dark.$mona-theme);
    @include telemedicine.mona-mic-button-theme(dark.$mona-theme);
    @include telemedicine.mona-sound-dialog-theme(dark.$mona-theme);
    @include telemedicine.mona-telemedicine-feature-theme(dark.$mona-theme);
}

.theme-mona-light {
    // Plus custom mixins for customizations
    @include components.ui-components-theme(light.$mona-theme);
    /* ---------------------------------------------------------------------- */
    /*                             API                                       */
    /* ---------------------------------------------------------------------- */
    @include api.mona-diagnostics-dialog-theme(light.$mona-theme);
    @include api.mona-server-url-dialog-theme(light.$mona-theme);
    /* ---------------------------------------------------------------------- */
    /*                             AUTH                                       */
    /* ---------------------------------------------------------------------- */
    @include auth.mona-lock-screen-theme(light.$mona-theme);
    @include auth.mona-signin-theme(light.$mona-theme);
    /* ---------------------------------------------------------------------- */
    /*                             DEVICE                                     */
    /* ---------------------------------------------------------------------- */
    @include device.mona-health-check-theme(light.$mona-theme);
    @include device.mona-step-actions-portal-theme(light.$mona-theme);
    @include device.mona-wifi-connect-dialog-theme(light.$mona-theme);
    @include device.mona-device-setup-theme(light.$mona-theme);
    /* ---------------------------------------------------------------------- */
    /*                             PDMS                                       */
    /* ---------------------------------------------------------------------- */
    @include pdms.mona-notifications-theme(light.$mona-theme);
    @include pdms.mona-patient-admission-search-theme(light.$mona-theme);
    @include pdms.mona-medication-theme(light.$mona-theme);
    @include pdms.mona-task-list-theme(light.$mona-theme);
    @include pdms.mona-wards-theme(light.$mona-theme);
    @include pdms.mona-encounter-theme(light.$mona-theme);
    @include pdms.mona-settings-theme(light.$mona-theme);
    @include pdms.mona-feature-prescription-sets-theme(light.$mona-theme);
    @include pdms.mona-patient-management-theme(dark.$mona-theme);
    @include pdms.pdms-shared-theme(light.$mona-theme);
    @include pdms.mona-workflows-theme(light.$mona-theme);
    @include pdms.mona-workflows-manage-theme(light.$mona-theme);
    @include pdms.mona-treatment-interventions-theme(light.$mona-theme);
    /* ---------------------------------------------------------------------- */
    /*                             TELEMEDICINE                               */
    /* ---------------------------------------------------------------------- */
    @include telemedicine.mona-camera-dialog-theme(light.$mona-theme);
    @include telemedicine.mona-mic-button-theme(light.$mona-theme);
    @include telemedicine.mona-sound-dialog-theme(light.$mona-theme);
    @include telemedicine.mona-telemedicine-feature-theme(light.$mona-theme);
}
