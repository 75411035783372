@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-ventilation-table-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);
    $primaryBackgroundColor: if(
        $is-dark,
        mat.get-color-from-palette($primary, A400),
        mat.get-color-from-palette($primary, 300)
    );
    $inputTextColor: if($is-dark, white, black);
    $inputBorderColor: if($is-dark, #bfbfbf, #707070);
    $addIconColor: if($is-dark, rgba(255, 255, 255, 0.08), rgba(0, 0, 0, 0.32));

    $rowHeight: 50px;
    $expRowHeight: 120px;

    .app-ventilation-table {
        // td.mat-column-overlayColumn {
        //     margin: -1px;
        //     mona-ventilation-table-cell {
        //         background: inherit;
        //         position: relative;

        //         &.mat-column-value {
        //             width: inherit;
        //         }
        //     }
        // }

        &__procedure-stop {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: calc(50% - 4px);
                left: calc(50% - 4px);
                width: 8px;
                height: 8px;
            }

            &-wrapper {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            border: 2px solid #000;

            @if $is-dark {
                border: 2px solid rgba(255, 255, 255, 0.64);
            }

            &:after {
                background-color: mat.get-color-from-palette($accent);
            }
        }

        &__table {
            .ui-table {
                &-td {
                    &.app-ventilation-table__table-cell {
                        padding: 0 !important;
                        min-height: $rowHeight - 2px;
                        height: $rowHeight - 2px;
                        width: inherit;
                    }

                    &--has-value {
                        cursor: cell;
                    }

                    &--disabled {
                        cursor: not-allowed !important;
                        pointer-events: none;
                    }

                    &--active {
                        .mat-column-value__icon-add {
                            display: inline-block !important;
                        }
                    }

                    &--active ~ * {
                        pointer-events: none;
                        > * {
                            display: none;
                        }
                    }

                    :not(.ui-table-td--active ~ *) {
                        .mat-column-value__icon-add {
                            display: none;
                        }
                    }
                }
                &-tr {
                    &:not(.ui-table-tr--expanded):not(.ui-table-tr--header):not(.ui-table-tr--top-header) {
                        height: $rowHeight;
                    }

                    &--activeVentilation {
                        z-index: 4;
                    }

                    &--expanded {
                        height: $expRowHeight;
                        .ui-table-td.app-ventilation-table__table-cell,
                        .ui-table-td.mat-column-overlayColumn,
                        .ui-table-td.mat-column-name {
                            min-height: $expRowHeight;
                            height: $expRowHeight;
                        }
                        details {
                            height: 60px;
                        }

                        .ui-table-td--name {
                            text-align: left;
                            details {
                                height: 120px;
                                summary {
                                    padding-top: 15px;
                                    padding-bottom: 8px;
                                }
                                > div {
                                    padding-left: 15px;
                                    line-height: 20px;
                                    padding-top: 10px;
                                }
                            }
                        }
                    }
                }
            }

            // Name column
            .mat-column-name {
                position: relative;
                color: mat.get-color-from-palette($foreground, text);
                font-size: 16px;
                font-weight: 500;
                min-height: inherit;

                &.ui-table-td {
                    text-align: left;
                }

                .mat-icon-inline {
                    width: 22px;
                    height: 22px;
                    vertical-align: bottom;
                }
            }

            // Value column
            .mat-column-value {
                font-size: 14px;
                font-weight: 500;
                -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

                details > summary::-webkit-details-marker {
                    display: none;
                }

                &:before,
                &:after {
                    background-color: mat.get-color-from-palette($primary, 300);

                    @if $is-dark {
                        background-color: mat.get-color-from-palette($primary, A400);
                    }
                }

                &--ventilation-start,
                &--ventilation-end,
                .app-ventilation-table__procedure-stop-wrapper {
                    &:before,
                    &:after {
                        background-color: mat.get-color-from-palette($primary, 300);

                        @if $is-dark {
                            background-color: mat.get-color-from-palette($primary, A400);
                        }
                    }
                }

                &:hover {
                    .mat-column-value__add {
                        color: rgba(0, 0, 0, 0.64);

                        @if $is-dark {
                            color: rgba(255, 255, 255, 0.64);
                        }
                    }
                }

                &__select {
                    .mat-mdc-select-arrow-wrapper {
                        display: none;
                    }
                }

                &__input {
                    height: 20px;
                    outline: none;
                    background-color: transparent;
                    border: 2px dashed $inputBorderColor;
                    white-space: nowrap;
                    font-weight: 500;

                    @if $is-dark {
                        border: 2px dashed #bfbfbf;
                        color: #fff;
                    }

                    &--invalid {
                        border: 2px dashed $mona-error;
                    }

                    &--disabled {
                        pointer-events: none;
                        color: inherit;
                        border: none !important;
                    }
                }

                &--changed {
                    color: $changes-color;
                }

                &--removed {
                    text-decoration: line-through;
                }

                &--warning {
                    color: mat.get-color-from-palette($warn);
                }

                &__parent--expanded {
                    height: 50px;
                }

                &__child {
                    line-height: 20px;
                    padding-top: 10px;
                }

                .group-inputs {
                    line-height: 30px;
                    input {
                        margin-bottom: 10px;
                    }
                }

                &--expanded {
                    height: $expRowHeight;
                    summary {
                        height: 60px;
                    }
                    summary::-webkit-details-marker {
                        display: inline-block;
                    }
                }

                // &--sticky {
                //     border: none !important;
                //     background: inherit;
                // }

                &__icon-add,
                &__icon-add-suggestion {
                    &.mat-icon {
                        width: 22px;
                        height: 22px;
                        font-size: 22px;
                        line-height: 22px;
                    }
                }

                &__icon-removed,
                &__icon-no-suggestion {
                    &.mat-icon {
                        width: 16px;
                        height: 16px;
                        font-size: 16px;
                        line-height: 16px;
                        margin-left: 4px;
                        vertical-align: sub; // TODO: check
                    }
                }

                &__icon-add {
                    color: $addIconColor;
                    vertical-align: middle;
                }
            }
        }

        // SVG LINE
        .rangesvg {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;

            &__line {
                stroke: $primaryBackgroundColor;
                stroke-width: 2;
            }
        }

        &__add {
            padding-top: 24px;
            padding-bottom: 24px;
            cursor: pointer;

            &__trigger {
                display: flex;
                align-items: center;
                @if $is-dark {
                    color: mat.get-color-from-palette($primary, A400);
                } @else {
                    color: mat.get-color-from-palette($primary);
                }

                &-label {
                    font-weight: 500;
                    font-size: 16px;
                }
            }

            @if $is-dark {
                border-right: 1px solid #403c69;
            } @else {
                border-right: 1px solid #ebebeb;
            }
        }

        &__modes-menu,
        &__add-menu {
            max-height: 300px;
        }

        details > summary {
            list-style: none;
            &:focus {
                outline: none;
            }
        }
    }
}
