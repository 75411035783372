:host {
    padding: 32px 64px;
    display: block;

    mona-encounter-screen-header {
        margin-bottom: 44px;
    }
}

@mixin workflows-container-theme($theme) {
    $is-dark: map-get($theme, is-dark);
    $textColor: if($is-dark, white, black);

    :host {
        color: $textColor;
    }
}
