@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-settings-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    ui-settings-layout {
        display: block;
        width: 100%;
        height: inherit;
        position: relative;
    }

    .mona-settings {
        padding: 42px 74px;
        height: inherit;
        min-height: calc(100vh - $mat-toolbar-height-desktop);
    }

    .mona-settings-section {
        &__content {
            overflow-y: auto;
            // position: relative;
            // z-index: 1;
            // width: 100%;
            // height: 100%;

            &.mat-mdc-card {
                padding: 0;
            }

            .mat-mdc-list {
                padding: 0;
            }

            .mat-mdc-list-item {
                height: 64px;
            }
        }

        &__title {
            font-size: 32px;
            font-weight: 300;
            margin-bottom: 22px;
            letter-spacing: 0.17px;
        }

        &__list-item {
            &-value {
                font-weight: 500;
                font-size: 14px;
                margin-left: auto;
                text-align: right;
            }

            &-control {
                margin-left: auto;
                width: auto;
                font-size: 14px;
                font-weight: 500;
            }
        }

        &__icon {
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            cursor: pointer;
            border: none;
            outline: none;
            margin-right: 16px;
            flex-shrink: 0;

            &.mat-icon {
                height: 22px;
                width: 20px;
                font-size: 30px;
            }
        }

        &__menu-trigger {
            text-transform: none;
            margin-right: -16px;
            padding: 0 16px;
            border-radius: 0;

            .mat-icon {
                margin: 0;
                display: inline-flex;
                justify-content: center;
                width: 18px;
            }
        }

        &__select-panel {
            .mat-mdc-select-value {
                max-width: 100%;
                width: auto;
            }
        }

        &__option-hint {
            margin-left: 8px;
        }
        &__title {
            @if $is-dark {
                color: rgba(255, 255, 255, 0.72);
            } @else {
                color: rgba(0, 0, 0, 0.72);
            }
        }

        &__list-item {
            outline: none;

            &-value {
                @if $is-dark {
                    color: rgba(255, 255, 255, 0.32);
                } @else {
                    color: rgba(0, 0, 0, 0.32);
                }

                &--colored {
                    @if $is-dark {
                        color: #fff;
                    } @else {
                        color: mat.get-color-from-palette($primary);
                    }
                }
            }

            &--colored {
                @if $is-dark {
                    color: #fff;
                } @else {
                    color: mat.get-color-from-palette($primary);
                }
            }
        }

        &__icon {
            @if $is-dark {
                background-color: rgba(255, 255, 255, 0.08);
                color: rgba(255, 255, 255, 0.72);
            } @else {
                background-color: rgba(0, 0, 0, 0.08);
                color: rgba(0, 0, 0, 0.72);
            }
        }

        &__select {
            .mat-mdc-select-value {
                max-width: 100% !important;
                width: auto !important;
            }

            @if $is-dark {
                .mat-mdc-select-value,
                .mat-mdc-select-arrow {
                    color: #fff;
                }
            } @else {
                .mat-mdc-select-value,
                .mat-mdc-select-arrow {
                    color: mat.get-color-from-palette($primary);
                }
            }
        }

        &__slide {
            @if $is-dark {
                .mat-mdc-slide-toggle-content {
                    color: #fff;
                }

                &.mat-mdc-slide-toggle {
                    --mdc-switch-selected-handle-color: rgba(255, 255, 255, 0.32);
                    --mdc-switch-selected-hover-handle-color: rgba(255, 255, 255, 0.32);
                    --mdc-switch-selected-focus-handle-color: rgba(255, 255, 255, 0.32);
                    --mdc-switch-selected-pressed-handle-color: rgba(255, 255, 255, 0.32);
                    --mdc-switch-unselected-hover-handle-color: rgba(255, 255, 255, 0.32);
                    --mdc-switch-unselected-focus-track-color: #ffffff8a;
                    --mdc-switch-unselected-hover-track-color: #ffffff8a;
                    --mdc-switch-unselected-pressed-handle-color: #ffffff8a;
                    --mdc-switch-unselected-pressed-track-color: #ffffff8a;
                    --mdc-switch-unselected-track-color: #ffffff8a;
                    --mdc-switch-selected-track-color: #ffffff8a;
                    --mdc-switch-selected-focus-track-color: #ffffff8a;
                    --mdc-switch-selected-hover-track-color: #ffffff8a;
                    --mdc-switch-selected-pressed-track-color: #ffffff8a;
                    --mdc-switch-selected-track-color: #ffffff8a;

                    .mdc-switch__shadow {
                        background-color: rgba(255, 255, 255, 0.32);
                    }

                    .mdc-switch__icons > svg {
                        display: none;
                    }

                    label {
                        color: #fff;
                        font-weight: 500;
                    }
                }
            } @else {
                .mat-mdc-slide-toggle-content {
                    color: mat.get-color-from-palette($primary);
                }

                &.mat-mdc-slide-toggle {
                    --mdc-switch-selected-focus-track-color: #673ab752;
                    --mdc-switch-selected-hover-track-color: #673ab752;
                    --mdc-switch-selected-pressed-track-color: #673ab752;
                    --mdc-switch-unselected-focus-track-color: rgba(104, 58, 183, 0.379);
                    --mdc-switch-unselected-hover-track-color: rgba(104, 58, 183, 0.379);
                    --mdc-switch-unselected-pressed-handle-color: rgba(104, 58, 183, 0.379);
                    --mdc-switch-unselected-pressed-track-color: rgba(104, 58, 183, 0.379);
                    --mdc-switch-unselected-track-color: rgba(104, 58, 183, 0.379);
                    --mdc-switch-selected-track-color: rgba(104, 58, 183, 0.379);

                    .mdc-switch__shadow {
                        background-color: lighten(mat.get-color-from-palette($primary), 20%);
                    }

                    .mdc-switch__icons > svg {
                        display: none;
                    }

                    label {
                        color: mat.get-color-from-palette($primary, 500);
                        font-weight: 500;
                    }
                }
            }
        }

        &__menu-trigger {
            @if $is-dark {
                color: #fff;

                .mat-icon {
                    color: rgba(255, 255, 255, 0.7);
                }
            } @else {
                color: mat.get-color-from-palette($primary);
            }
        }

        &__option--highlighted {
            background-color: mat.get-color-from-palette($primary, 0.12);
        }
    }
}
