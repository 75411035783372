@use '@angular/material' as mat;

:host {
    .wli-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        font-size: 16px;
        border-bottom: 1px solid #0000001f;

        .wli-type-actions {
            display: flex;
            align-items: center;

            mat-slide-toggle {
                margin-right: 10px;
            }
        }
    }
}

@mixin workflow-list-item-theme($theme) {
    $is-dark: map-get($theme, is-dark);
    $primary: map-get($theme, primary);

    mat-slide-toggle {
        @if $is-dark {
            .mat-mdc-slide-toggle-content {
                color: #fff;
            }

            &.mat-mdc-slide-toggle {
                --mdc-switch-selected-handle-color: rgba(255, 255, 255, 0.32);
                --mdc-switch-selected-hover-handle-color: rgba(255, 255, 255, 0.32);
                --mdc-switch-selected-focus-handle-color: rgba(255, 255, 255, 0.32);
                --mdc-switch-selected-pressed-handle-color: rgba(255, 255, 255, 0.32);
                --mdc-switch-unselected-hover-handle-color: rgba(255, 255, 255, 0.32);
                --mdc-switch-unselected-focus-track-color: #ffffff8a;
                --mdc-switch-unselected-hover-track-color: #ffffff8a;
                --mdc-switch-unselected-pressed-handle-color: #ffffff8a;
                --mdc-switch-unselected-pressed-track-color: #ffffff8a;
                --mdc-switch-unselected-track-color: #ffffff8a;
                --mdc-switch-selected-track-color: #ffffff8a;
                --mdc-switch-selected-focus-track-color: #ffffff8a;
                --mdc-switch-selected-hover-track-color: #ffffff8a;
                --mdc-switch-selected-pressed-track-color: #ffffff8a;
                --mdc-switch-selected-track-color: #ffffff8a;

                .mdc-switch__shadow {
                    background-color: rgba(255, 255, 255, 0.32);
                }

                .mdc-switch__icons > svg {
                    display: none;
                }

                label {
                    color: #fff;
                    font-weight: 500;
                }
            }
        } @else {
            .mat-mdc-slide-toggle-content {
                color: mat.get-color-from-palette($primary);
            }

            &.mat-mdc-slide-toggle {
                --mdc-switch-selected-focus-track-color: #673ab752;
                --mdc-switch-selected-hover-track-color: #673ab752;
                --mdc-switch-selected-pressed-track-color: #673ab752;
                --mdc-switch-unselected-focus-track-color: rgba(104, 58, 183, 0.379);
                --mdc-switch-unselected-hover-track-color: rgba(104, 58, 183, 0.379);
                --mdc-switch-unselected-pressed-handle-color: rgba(104, 58, 183, 0.379);
                --mdc-switch-unselected-pressed-track-color: rgba(104, 58, 183, 0.379);
                --mdc-switch-unselected-track-color: rgba(104, 58, 183, 0.379);
                --mdc-switch-selected-track-color: rgba(104, 58, 183, 0.379);

                .mdc-switch__shadow {
                    background-color: lighten(mat.get-color-from-palette($primary), 20%);
                }

                .mdc-switch__icons > svg {
                    display: none;
                }

                label {
                    color: mat.get-color-from-palette($primary, 500);
                    font-weight: 500;
                }
            }
        }
    }
}
