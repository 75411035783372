@use '@angular/material' as mat;
@import '../variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin custom-keyframes-themes($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    $required-label-color: mat.get-color-from-palette($accent, text);

    // Adds themed keyframe: https://stackoverflow.com/questions/57419583/how-to-create-theme-based-css-animations-in-angular-material
    $keyframes-name: map-get($theme, keyframes);

    // Pulsing animation
    @keyframes #{$keyframes-name} {
        0% {
            @if $is-dark {
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
            } @else {
                box-shadow: 0 0 0 0 mat.get-color-from-palette($primary, 400, 0.7);
            }
        }

        20% {
            @if $is-dark {
                box-shadow: 0 0 14px 10px rgba(255, 255, 255, 0.3);
            } @else {
                box-shadow: 0 0 14px 10px mat.get-color-from-palette($primary, 400, 0.7);
            }
        }

        40% {
            @if $is-dark {
                box-shadow: 0 0 8px 6px rgba(255, 255, 255, 0.3);
            } @else {
                box-shadow: 0 0 8px 6px mat.get-color-from-palette($primary, 400, 0.7);
            }
        }

        60% {
            @if $is-dark {
                box-shadow: 0 0 10px 8px rgba(255, 255, 255, 0.3);
            } @else {
                box-shadow: 0 0 10px 8px mat.get-color-from-palette($primary, 400, 0.7);
            }
        }

        100% {
            @if $is-dark {
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
            } @else {
                box-shadow: 0 0 0 0 mat.get-color-from-palette($primary, 400, 0.7);
            }
        }
    }
}
