@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-vital-signs-table-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);
    $primaryFColor: if($is-dark, mat.get-color-from-palette($primary, A400), mat.get-color-from-palette($primary));
    $primaryBackgroundColor: if(
        $is-dark,
        mat.get-color-from-palette($primary, A400),
        mat.get-color-from-palette($primary, 300)
    );
    $inputTextColor: if($is-dark, white, black);
    $inputBorderColor: if($is-dark, #bfbfbf, #707070);
    $addIconColor: if($is-dark, rgba(255, 255, 255, 0.08), rgba(0, 0, 0, 0.32));

    $rowHeight: 50px;
    $expRowHeight: 120px;

    .app-vital-signs-table {
        &__element {
            width: 100%;
        }

        &__add__trigger {
            display: flex;
            align-items: center;
            color: $primaryFColor;

            &-label {
                font-weight: 500;
                font-size: 16px;
            }
        }

        &__modes-menu,
        &__add-menu {
            max-height: 300px;
        }

        .ui-table {
            &-tr {
                &--readonly {
                    pointer-events: none;
                }

                &--footer {
                    height: $rowHeight + 2;
                }

                &:not(.ui-table-tr--expanded):not(.ui-table-tr--header):not(.ui-table-tr--top-header) {
                    height: $rowHeight;
                }

                &--expanded {
                    height: $expRowHeight;
                    .ui-table-td.app-ventilation-table__table-cell,
                    .ui-table-td.mat-column-overlayColumn,
                    .ui-table-td.mat-column-name {
                        min-height: $expRowHeight;
                        height: $expRowHeight;
                    }
                    details {
                        height: 60px;
                    }

                    .ui-table-td--name {
                        text-align: left;
                        details {
                            height: 120px;
                            summary {
                                padding-top: 15px;
                                padding-bottom: 8px;
                            }
                            > div {
                                padding-left: 15px;
                                line-height: 20px;
                                padding-top: 10px;
                            }
                        }
                    }
                }
            }

            &-td {
                background-color: transparent;

                &--active ~ * {
                    pointer-events: none;
                    background-color: mat.get-color-from-palette($background, card) !important;
                    z-index: 12;

                    .mat-column-value__icon-add,
                    .rangesvg {
                        display: none;
                    }
                }

                // INFO: https://stackoverflow.com/a/72523097/4115894
                // &:not(.ui-table-td--has-value:first-of-type ~ .ui-table-td--has-value),
                &--first ~ *:not(.ui-table-td--has-value),
                &--overlay:empty ~ .ui-table-td--has-value:first-of-type {
                    z-index: 12;
                }

                &--active {
                    // INFO: this is how we show numeric value over horizontal line
                    .mat-column-value__text > span {
                        background-color: unset !important;
                    }

                    // TODO: try to use bg plus instead of material icon
                    /* &:empty {
                        background-image: url('data:image/svg+xml;utf8,<svg height="48" viewBox="0 96 96 96" width="48"><path d="M450 856V606H200v-60h250V296h60v250h250v60H510v250h-60Z"/></svg>');
                        background-position: center;
                    } */
                }

                &--has-value {
                    // INFO: this is how we show numeric value over horizontal line
                    .mat-column-value__text > span {
                        background-color: mat.get-color-from-palette($background, card) !important;
                    }
                }

                &--overlay {
                    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
                }
            }
        }

        .mat-mdc-select {
            font-weight: 500;
            letter-spacing: normal;
        }

        // Name column
        .mat-column-name {
            position: relative;
            color: mat.get-color-from-palette($foreground, text);
            font-size: 16px;
            font-weight: 500;
            min-height: inherit;

            &__label {
                display: flex;
                justify-content: left;
                align-items: center;
            }

            &.ui-table-td {
                text-align: left;
            }

            .mat-icon-inline {
                width: 22px;
                height: 22px;
                vertical-align: bottom;
                margin-left: 1em;
            }
        }

        // Value column
        .mat-column-value {
            font-size: 14px;
            font-weight: 500;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

            details > summary::-webkit-details-marker {
                display: none;
            }

            details {
                height: inherit;
            }

            &--changed {
                color: $changes-color;
                stroke: $changes-color;
            }

            &--removed {
                cursor: default;
                text-decoration: line-through;
                pointer-events: none;
            }

            &--expanded {
                height: $expRowHeight;
                summary {
                    height: 60px;
                }
                summary::-webkit-details-marker {
                    display: inline-block;
                }
            }

            &__child {
                line-height: 20px;
                padding-top: 8px;
            }

            &__text {
                position: relative;
                display: inline;
                span:not(:empty) {
                    padding: 0 8px;
                    background-color: mat.get-color-from-palette($background, card) !important;
                }
            }

            &__icon-add,
            &__icon-add-suggestion {
                &.mat-icon {
                    width: 22px;
                    height: 22px;
                    font-size: 22px;
                    line-height: 22px;
                }
            }

            &__icon-removed,
            &__icon-no-suggestion {
                &.mat-icon {
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    line-height: 16px;
                    margin-left: 4px;
                }
            }

            &__icon-add {
                color: $addIconColor;
                vertical-align: middle;
            }
        }
        // SVG LINE
        .rangesvg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;

            &__line {
                stroke: $primaryBackgroundColor;
                stroke-width: 2;
            }
        }

        details > summary {
            list-style: none;
            &:focus {
                outline: none;
            }
        }
    }
}
