@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin custom-toggle-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    /////////// Make mat-toggle-button behave like other buttons

    .mat-button-toggle-checked {
        background-color: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, default-contrast) !important;
    }
}

.mdc-switch:disabled {
    opacity: 0.38;
}
