@use '@angular/material' as mat;

@import 'containers/basic-care/basic-care.component.theme';
@import 'containers/entry-control/entry-control.component.theme';
@import 'components/care-check-form/care-check-form.component.theme';
@import 'components/entry-control-step/entry-control-step.component.theme';
@import 'components/glasgow-coma-scale-form/glasgow-coma-scale-form.component.theme';
@import 'care-reports.component';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin care-reports-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .app-care-reports {
        //
    }

    /* ---------------------------------------------------------------------- */
    /* CHILDREN */
    /* ---------------------------------------------------------------------- */
    @include app-basic-care-theme($theme);
    @include app-care-check-form-theme($theme);
    @include app-entry-control-section-confirm-theme($theme);
    @include app-entry-control-step-theme($theme);
    @include app-entry-control-theme($theme);
    @include app-glasgow-coma-scale-form-theme($theme);
}
