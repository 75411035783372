@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-lab-values-table-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);
    $primaryTextColor: if($is-dark, mat.get-color-from-palette($primary, A400), mat.get-color-from-palette($primary));
    $primaryBackgroundColor: if(
        $is-dark,
        mat.get-color-from-palette($primary, A400),
        mat.get-color-from-palette($primary, 300)
    );
    $addIconColor: if($is-dark, rgba(255, 255, 255, 0.08), rgba(0, 0, 0, 0.32));
    $topHeaderColor: if($is-dark, rgba(255, 255, 255, 0.32), rgba(0, 0, 0, 0.32));
    $inputTextColor: if($is-dark, white, black);
    $inputBorderColor: if($is-dark, #bfbfbf, #707070);

    $rowHeight: 50px;

    .mona-lab-values-table {
        &__add {
            &__trigger {
                color: $primaryTextColor;
                display: flex;
                align-items: center;
            }
        }

        .value-type-search-input {
            @if $is-dark {
                color: #fff;
            }
        }

        &__table {
            width: 100%;

            .ui-table {
                &--top-header {
                    // width: auto; TODO: fix 2 day case
                }

                &-td {
                    //
                }

                &-th {
                    &--filter,
                    &--top-header {
                        color: $topHeaderColor !important;
                    }
                }

                &-tr {
                    &:not(.ui-table-tr--group):not(.ui-table-tr--header):not(.ui-table-tr--top-header) {
                        height: $rowHeight;
                    }
                }
            }

            .mat-column-name {
                &__label {
                    overflow: hidden;
                    text-wrap: nowrap;
                    text-overflow: ellipsis;
                    text-align: left;
                }
                .mat-icon {
                    width: 22px;
                    height: 22px;
                    font-size: 22px;
                    line-height: 22px;
                }
            }

            .mat-column-value {
                text-align: center;
                font-size: 14px;
                font-weight: 500;

                &__icon-add,
                &__icon-add-suggestion {
                    &.mat-icon {
                        width: 22px;
                        height: 22px;
                        font-size: 22px;
                        line-height: 22px;
                    }
                }

                &__icon-removed,
                &__icon-range {
                    &.mat-icon {
                        width: 16px;
                        height: 16px;
                        font-size: 16px;
                        line-height: 16px;
                        vertical-align: text-bottom;
                        margin-left: 4px;
                    }
                }

                &__icon-add {
                    color: $addIconColor;
                }

                &__wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span {
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                &--editing {
                    z-index: 1;
                }

                &__input {
                    display: inline-flex;
                    outline: none;
                    background-color: transparent;
                    color: $inputTextColor;
                    border: 2px dashed $inputBorderColor;
                    white-space: nowrap;
                    font-weight: 500;
                    // font-size: 16px;
                    padding: 4px;

                    &:disabled {
                        color: inherit;
                        border: none !important;
                        padding: 0;
                    }

                    &--invalid {
                        border: 2px dashed $mona-error;
                    }
                }

                &--warning {
                    color: mat.get-color-from-palette($warn);
                }

                &--changed {
                    color: $changes-color;
                }

                &--removed {
                    text-decoration: line-through;
                }

                &--automated {
                    pointer-events: none;
                }
            }
        }
    }
}
