@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-inputs-table-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);
    $primary-text: map-get($theme, primary-text);

    $rowHeight: 50px;
    $expRowHeight: 120px;

    $primaryBackgroundColor: if(
        $is-dark,
        mat.get-color-from-palette($primary, A400),
        mat.get-color-from-palette($primary, 300)
    );
    $addIconColor: if($is-dark, rgba(255, 255, 255, 0.08), rgba(0, 0, 0, 0.32));

    .app-inputs-table {
        details {
            pointer-events: none;
            outline: none;
            > summary {
                list-style: none;
            }
            > summary::-webkit-details-marker {
                display: none;
            }
        }

        &__container {
            //
        }

        &__table {
            .ui-table {
                &-td {
                    &.app-inputs-table__table-cell {
                        padding: 0 !important;
                        min-height: $rowHeight - 2px;
                    }

                    &--has-value {
                        // cursor: cell;
                    }

                    &--disabled {
                        cursor: not-allowed !important;
                        pointer-events: none;
                        background-color: rgba(0, 0, 0, 0.09);
                    }

                    &--active {
                        .mat-column-value__icon-add {
                            display: inline-block !important;
                        }
                    }

                    &--active ~ * {
                        pointer-events: none;
                        .mat-column-value__icon-add-suggestion {
                            opacity: 0.4;
                        }
                        .mat-column-value__icon-add {
                            display: none;
                        }

                        svg {
                            display: none;
                        }
                    }

                    :not(.ui-table-td--active ~ *) {
                        .mat-column-value__icon-add {
                            display: none;
                        }
                    }

                    .mat-mdc-list-item-title {
                        height: 100%;
                        display: flex;
                        flex-direction: column;

                        & .mona-medication-item__title {
                            height: 45px;
                        }

                        & .mona-medication-item__subtitle {
                            height: 36px;
                        }
                    }
                }

                &-tr {
                    &:not(.ui-table-tr--group):not(.ui-table-tr--header):not(.ui-table-tr--top-header) {
                        height: $rowHeight;
                    }

                    // &--expanded {
                    //     height: $expRowHeight;
                    //     .ui-table-td.app-inputs-table__table-cell,
                    //     .ui-table-td.mat-column-overlayColumn,
                    //     .ui-table-td.mat-column-name {
                    //         min-height: $expRowHeight;
                    //         height: $expRowHeight;
                    //     }
                    // }

                    &--has-values {
                        z-index: 4;

                        .mat-badge-medium.mat-badge-after .mat-badge-content {
                            right: -24px;
                            top: -2px;
                        }
                    }

                    &--readonly {
                        z-index: 4;
                    }

                    &.mat-mdc-footer-row {
                        display: none;
                    }
                }
            }

            // Filter header
            .mat-header-cell.mat-column-name {
                color: $primary-text;

                @if $is-dark {
                    border-right: 1px solid #403c69;
                } @else {
                    border-right: 1px solid #ebebeb;
                }
            }

            // Value label column
            .mat-column-name {
                position: relative;
                color: mat.get-color-from-palette($foreground, text);
                font-size: 16px;
                font-weight: 500;
                min-height: inherit;

                &.ui-table-td {
                    text-align: left;
                }

                &.mat-table-sticky {
                    position: sticky;
                }

                .mat-mdc-form-field-hint {
                    font-size: 14px;
                    font-weight: 400;
                }

                &__suggestion {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 1rem;
                    font-size: 12px !important;
                    font-weight: 300 !important;
                }
            }

            .mat-column-value {
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
                min-height: $rowHeight;
                min-width: inherit;

                &__details {
                    display: block;
                    width: inherit;
                    &-block > * {
                        padding: 0 4px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        font-size: 12px !important;
                        font-weight: normal;
                        @extend .text-muted;
                    }
                }

                // &--expanded {
                //     height: $expRowHeight;
                // }

                &--changed {
                    color: $changes-color;
                    stroke: $changes-color;
                    .rangesvg > .rangesvg__line {
                        stroke: $changes-color;
                    }
                }

                &--removed {
                    cursor: default;
                    text-decoration: line-through;
                    pointer-events: none;
                }

                &--not-relevant {
                    // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAKklEQVQIW2NkYGDwAeItQAwDYD4jlAeThCuCSYDkUXTi1YHXDiS7wUwfAAHMCVMwEJUcAAAAAElFTkSuQmCC);
                    background-color: mat.get-color-from-palette($foreground, null, 0.6);
                }

                // Days which are not available for adding more inputs
                &--disabled:not(.mat-column-value--sticky) {
                    // background-color: mat.get-color-from-palette($foreground, null, 0.6);
                    .mat-column-value__icon-add,
                    .mat-column-value__icon-add-suggestion {
                        opacity: 0.4;
                    }
                }

                &--not-relevant {
                    .mat-column-value__icon-add,
                    .mat-column-value__icon-add-suggestion {
                        display: none !important;
                    }
                }

                &__icon-add,
                &__icon-add-suggestion {
                    &.mat-icon {
                        width: 22px;
                        height: 22px;
                        font-size: 22px;
                        line-height: 22px;
                    }
                }

                &__icon-removed,
                &__icon-no-suggestion {
                    &.mat-icon {
                        width: 16px;
                        height: 16px;
                        font-size: 16px;
                        line-height: 16px;
                        vertical-align: text-top;
                        margin-left: 4px;
                    }
                }

                &__icon-add {
                    color: $addIconColor;
                    // display: none;
                }
                &__icon-no-suggestion {
                    color: $changes-color;
                }

                /* ---------------------------------------------------------- */
                /* RANGE `*/
                /* ---------------------------------------------------------- */

                &--range {
                    //
                }

                &--range-end-active:not(.mat-column-value--range-value) {
                    .mat-column-value__icon-add {
                        display: block;
                    }
                }

                &--range-not-addable {
                    .mat-column-value__icon-add-suggestion {
                        // display: none;
                    }
                }
            }
        }

        // SVG LINE
        .rangesvg {
            width: 100%;
            height: 78px;
            position: absolute;

            &__line {
                stroke: $primaryBackgroundColor;
                stroke-width: 2;
            }
        }
    }

    .multiple-values-overlay {
        & p {
            color: $primary-text;
        }

        &__table {
            color: $primary-text;

            & .overlay-control {
                @if $is-dark {
                    color: $primary-text;
                } @else {
                    color: mat.get-color-from-palette($primary, 500) !important;
                }
            }
        }
    }
}
