@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-tab-bar-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $white: map-get($theme, white);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $primaryBackgroundColor: if(
        $is-dark,
        mat.get-color-from-palette($primary, A400),
        mat.get-color-from-palette($primary, 300)
    );

    $bg-border-color: if($is-dark, #403c69, #ebebeb);
    $bg-color: if($is-dark, rgba(255, 255, 255, 0.72), white);
    $fg-color: if($is-dark, #373267, mat.get-color-from-palette($foreground));
    $tabLinkColor: if($is-dark, rgba(255, 255, 255, 0.72), $fg-color);
    $activeColor: if($is-dark, mat.get-color-from-palette($primary, A400), mat.get-color-from-palette($primary, 500));

    .mat-mdc-tab,
    .mat-mdc-tab-link {
        &.mdc-tab--active .mdc-tab__text-label {
            @if $is-dark {
                color: mat.get-color-from-palette($primary, A400);
            } @else {
                color: mat.get-color-from-palette($primary, 500);
            }
        }

        .mdc-tab__text-label,
        &:hover .mdc-tab__text-label,
        &:focus .mdc-tab__text-label {
            @if $is-dark {
                color: rgba(255, 255, 255, 0.72);
            } @else {
                color: #000;
            }
        }

        letter-spacing: normal;
    }

    .mdc-tab__text-label .icon-before.mat-icon {
        order: -1;
    }

    .mat-mdc-tab-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .ui-tab-bar {
        &--with-elevation {
            @include mat.elevation(4);
        }

        &__nav {
            a:visited {
                color: unset;
            }

            a.mat-mdc-tab-link {
                opacity: 1;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 500;
                max-width: 200px;
                display: flex;

                &.mdc-tab--active {
                    color: $activeColor;
                }
            }

            &.mat-mdc-tab-header {
                border-bottom: none;
            }

            .mat-mdc-tab-links {
                align-items: center;
            }

            &--stretched {
                a.mat-mdc-tab-link {
                    flex-grow: 1;
                }
            }

            &--stepper {
                .mat-stepper-horizontal-line {
                    border-top-width: 1px;
                    border-top-style: solid;
                    flex: auto;
                    height: 0;
                    margin: 0 -16px;
                    min-width: 32px;
                    border-top-color: #0000001f;
                }

                .mdc-tab-indicator {
                    visibility: hidden !important;
                }

                a.mat-mdc-tab-link.mdc-tab--active {
                    color: mat.get-color-from-palette($foreground);
                    font-weight: bold;
                }

                .mat-step-label {
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    text-transform: none !important;
                    fill: $tabLinkColor;
                }

                .mat-step-label > mat-icon {
                    fill: $tabLinkColor;
                }
            }
        }

        &__actions {
            &-block {
                position: absolute;
                right: 0;
                top: 4px;
                min-width: 200px;
                display: flex;
                justify-content: flex-end;
            }

            .mdc-button {
                color: $activeColor;
                font-size: 18px;
                font-weight: 500;
                text-transform: none;

                .mat-icon {
                    margin-left: 4px;
                    margin-right: 0;
                }
            }
        }
    }
}
