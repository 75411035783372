@use 'sass:math';

@function toRem($value) {
    $remValue: math.div($value, 16) + rem;
    @return $remValue;
}

/** Colors **/
$mona-background-color-light: #f5f4fb;
$mona-background-color-dark: #272452;
$pdms-background-color-warning: #ff6f00;
$pdms-border-color: #673ab7;

$dark-theme-error: #e57373;
$mona-error: #f11c1c;

/** Main navigation bar **/
$navbar-width: 96px;
$mona-navbar-border-radius: 64px;

/** Encounter navigation bar **/
$mona-encounter-navbar-width: 450px;

/** Alerts toolbar **/
$mona-alerts-toolbar-height: toRem(48);
/** Sidebar **/
$mona-sidebar-width: 518px;
$mona-sidebar-border-radius: 64px;
$mona-drawer-layout-fab-position-top: 1.875rem;

/** keyboard **/
$mona-keyboard-height: 384px;
$mona-keyboard-width: 100vw;
$mona-conversation-input-height: 64px;
$mona-keyboard-height-with-input: 64px + 384px;

$scrollbar-size: 12px;

// Unit sizes
$unit-o: 0.05rem !default;
$unit-h: 0.1rem !default;
$unit-1: 0.2rem !default;
$unit-2: 0.4rem !default;
$unit-3: 0.6rem !default;
$unit-4: 0.8rem !default;
$unit-5: 1rem !default;
$unit-6: 1.2rem !default;
$unit-7: 1.4rem !default;
$unit-8: 1.6rem !default;
$unit-9: 1.8rem !default;
$unit-10: 2rem !default;
$unit-12: 2.4rem !default;
$unit-16: 3.2rem !default;

/**
 * The list of possible z-index values
 * (items at the top have a higher value)
 * @section Config
 */
$z-layers: (
    'global-overlay',
    'global-header',
    'drawer',
    'tooltip',
    'attached-panel-overlay',
    'panel-header',
    'menu-trigger'
);

$loading-overlay-z: 101;
$hey-mona-z: 1001;
$mona-sidenav-z: 103;

$mat-toolbar-height-desktop: 64px;
