@use '@angular/material' as mat;
@import 'variables';

:host {
    .wec-header {
        font-size: 32px;
        font-weight: 300;
        display: flex;
        align-items: center;
        line-height: normal;
    }

    .wec-container {
        .wec-btn {
            font-weight: 400;
            text-transform: capitalize;
            min-height: 64px;

            .mat-icon {
                order: 1;
            }
        }

        .wec-actions {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
        }

        .wec-section {
            overflow: hidden;
            margin-top: 20px;
            border-radius: 8px;
            box-shadow: 0px 0px 2px #00000024, 0px 2px 2px #0000001f, 0px 1px 3px #00000033;

            .wec-new-el {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 14px 40px;
                width: 100%;
                border-radius: 8px;

                mat-icon {
                    margin-left: 15px;
                }
            }

            mat-form-field {
                padding-top: 20px;
                padding-left: 40px;
                min-width: 50%;
            }
        }

        .wec-questions {
            // settings layout paddings = 72px * 2, title = 38px, top actions = 56px, bot actions = 56px
            // workflow type info = 111px, add element btn = 84px, questions margin = 20px
            max-height: calc(100vh - 144px - 38px - 56px - 56px - 111px - 84px - 20px);
            overflow: auto;
            padding: 2px;
            margin: 20px -2px;
        }

        .keyboard-spacing {
            max-height: calc(100vh - 144px - 38px - 56px - 56px - 111px - 84px - 20px - #{$mona-keyboard-height});
            padding-bottom: $mona-keyboard-height;
        }
    }
}

@mixin workflow-edit-container-theme($theme) {
    $background: map-get($theme, background);

    .wec-section {
        background-color: mat.get-color-from-palette($background, card);
    }
}
