@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-diagnostics-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mona-diagnostics {
        display: block;
        width: 100%;
        max-height: inherit;

        > .mat-mdc-card {
            padding: 0;
            box-shadow: none;
        }

        &__status {
            font-weight: normal;
            margin: 0;
        }

        &__head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;

            &--margined {
                margin-top: 16px;
            }
        }

        &__list {
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        &__head {
            color: #fff;
            @if $is-dark {
                background-color: #242442;
            } @else {
                background-color: mat.get-color-from-palette($primary);
            }
        }

        &__refresh {
            color: #fff;
        }

        &__list {
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-top: none;
        }

        &__item:not(:last-child) {
            mat-list-item,
            &.mat-mdc-list-item {
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }
        }

        .ui-info-list-item-content .ui-info-list-item-title-wrapper {
            font-weight: normal !important;
        }

        .mat-mdc-tab-body {
            min-height: 480px;
            overflow-y: hidden;

            &-content {
                padding: 0;

                .mat-mdc-progress-bar {
                    top: -1px;
                }
            }
        }

        .mdc-tab {
            height: 64px;
            font-size: 18px;

            &__content {
                .mat-icon {
                    color: #fff;
                    margin-right: 12px;
                }
            }
        }
    }

    .mona-diagnostics-dialog {
        &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
        }

        &__title {
            margin: 0;
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            position: relative;
            max-height: 80vh;
        }

        &__main {
            margin: 0 auto;
        }

        &__close {
            opacity: 0.54;
        }
        .mat-dialog-container {
            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            }
        }

        &__title {
            color: #000;

            @if $is-dark {
                color: #fff;
            }
        }
    }
}
