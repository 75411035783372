@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-balance-table-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: mat.get-color-from-palette($accent);
    $inputTextColor: if($is-dark, white, black);
    $inputBorderColor: if($is-dark, #bfbfbf, #707070);
    $primaryTextColor: if($is-dark, mat.get-color-from-palette($primary, A400), mat.get-color-from-palette($primary));
    $primaryBackgroundColor: if(
        $is-dark,
        mat.get-color-from-palette($primary, A400),
        mat.get-color-from-palette($primary, 300)
    );
    $addIconColor: if($is-dark, rgba(255, 255, 255, 0.08), rgba(0, 0, 0, 0.32));

    $rowHeight: 50px;

    .app-balance-table {
        &__container {
            &-spinner {
                @if $is-dark {
                    background-color: rgba(0, 0, 0, 0.5);
                } @else {
                    background-color: rgba(255, 255, 255, 0.5);
                }
            }
        }

        &__table {
            width: 100%;
            .ui-table {
                &-tr {
                    &.ui-table-tr--current,
                    &.ui-table-tr--currentInput,
                    &.ui-table-tr--currentOutput,
                    &.ui-table-tr--total {
                        pointer-events: none;
                    }

                    &.ui-table-tr--current {
                        td.ui-table-td--active ~ * {
                            svg {
                                display: none;
                            }
                        }
                    }
                }

                td.mat-column-overlayColumn {
                    mona-balance-table-cell {
                        background: inherit;
                        position: relative;

                        &.mat-column-value {
                            width: inherit;
                        }
                    }
                }

                &-td {
                    &.app-balance-table__table-cell,
                    &.mat-column-overlayColumn {
                        padding: 0 !important;
                        min-height: $rowHeight - 2px;
                    }

                    &--active ~ * {
                        pointer-events: none;
                        .mat-column-value__icon-add-suggestion {
                            opacity: 0.4;
                        }
                        .mat-column-value__icon-add {
                            display: none;
                        }
                    }

                    &--active {
                        .mat-column-value__icon-add {
                            display: inline-block !important;
                            overflow: inherit;
                        }
                    }
                }

                // Date header
                .mat-header-cell.mat-column-date {
                    color: rgba(0, 0, 0, 0.32);

                    @if $is-dark {
                        color: rgba(255, 255, 255, 0.32);
                    }

                    &:not(:nth-child(2)) {
                        border-left: 1px solid #ebebeb;

                        @if $is-dark {
                            border-left: 1px solid #403c69;
                        }
                    }
                }

                // Value label column
                .mat-mdc-cell.mat-column-name {
                    color: mat.get-color-from-palette($foreground, text);

                    @if $is-dark {
                        border-right: 1px solid #403c69;
                    } @else {
                        border-right: 1px solid #ebebeb;
                    }
                }

                .mat-column-name {
                    &__label {
                        display: flex;
                        align-items: center;
                        mat-icon {
                            margin-left: 1rem;
                        }
                    }
                }

                .mat-mdc-cell .mat-column-value {
                    @if $is-dark {
                        border-left: 1px solid #403c69;
                    } @else {
                        border-left: 1px solid #ebebeb;
                    }
                }

                .mat-header-cell:nth-child(2),
                .mat-mdc-cell:nth-child(2) {
                    .mat-column-value:first-child {
                        border-left: none;
                    }
                }
            }

            .mat-column-value {
                &__add {
                    color: rgba(0, 0, 0, 0.32);

                    @if $is-dark {
                        color: rgba(255, 255, 255, 0.08);
                    }
                }

                &:hover {
                    .mat-column-value__add {
                        color: rgba(0, 0, 0, 0.64);

                        @if $is-dark {
                            color: rgba(255, 255, 255, 0.64);
                        }
                    }
                }

                &__input {
                    display: inline-flex;
                    outline: none;
                    background-color: transparent;
                    color: $inputTextColor;
                    border: 2px dashed $inputBorderColor;
                    white-space: nowrap;
                    font-weight: 500;
                    height: 20px;
                    padding: 4px;

                    &:disabled {
                        color: inherit;
                        border: none !important;
                        padding: 0;
                    }

                    &--invalid {
                        border: 2px dashed $mona-error;
                    }
                }

                &--warning {
                    color: mat.get-color-from-palette($warn);
                }

                &--changed {
                    color: $changes-color;
                }

                &--removed {
                    text-decoration: line-through;
                }

                &__icon-add {
                    color: $addIconColor;

                    &.mat-icon {
                        width: 22px;
                        height: 22px;
                        font-size: 22px;
                        line-height: 56px;
                    }
                }
            }
            // SVG LINE
            .rangesvg {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;

                &__line {
                    stroke: $primaryBackgroundColor;
                    stroke-width: 2;
                }
            }
        }
    }
}
