// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-step-actions-portal-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .step-actions-portal {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__aside,
        &__actions {
            display: flex;
            align-items: center;
            &:empty {
                display: none;
            }
        }
        &__aside {
            justify-content: flex-start;
        }
        &__actions {
            flex-grow: 1;
            justify-content: flex-end;

            .mdc-button:not(:first-of-type) {
                margin-left: 32px;
            }
        }
    }
}
