@use '@angular/material' as mat;
@use '@angular/material-experimental' as mat-experimental;
@import 'variables';
$scrollbar-size: 12px;
$rowHeight: 50px;
$cellZindex: 100;

/**
 * Retrieve
 */
@mixin border($name, $is-dark) {
    $borders: ('top', 'bottom', 'left', 'right');
    @if index($borders, $name) {
        @if $is-dark {
            border-#{$name}: 1px solid #403c69;
        } @else {
            border-#{$name}: 1px solid #ebebeb;
        }
    }
}

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-table-theme($theme) {
    // Additional Material
    @include mat-experimental.popover-edit-theme($theme);
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);
    $primaryBackgroundColor: if(
        $is-dark,
        mat.get-color-from-palette($primary, A400),
        mat.get-color-from-palette($primary, 300)
    );

    $primary-text: map-get($theme, primary-text);
    $bg-border-color: if($is-dark, #403c69, #ebebeb);
    $bg-color: if($is-dark, #373267, white);
    $addIconColor: if($is-dark, rgba(255, 255, 255, 0.08), rgba(0, 0, 0, 0.32));

    %theme-color-text {
        @if $is-dark {
            color: rgba(255, 255, 255, 0.32);
        } @else {
            color: rgba(0, 0, 0, 0.32);
        }
    }

    %theme-color-text-header {
        color: $primary-text;
    }

    ui-vhs-table {
        display: block;
        width: 100%;
        height: inherit;
        position: relative;
    }

    .keyboard-spacing .ui-table-wrapper table {
        margin-bottom: $mona-keyboard-height;
    }

    .ui-table-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .ui-table {
        width: 100%;
        border: 0;
        border-spacing: 0;
        table-layout: fixed;
        // background: #fff;

        @if $is-dark {
            background: mat.get-color-from-palette($primary, 600);
        }

        &--layout-auto {
            table-layout: auto;
        }

        &--max-scroll {
            height: calc(100vh + #{$mona-keyboard-height});
        }

        &-loader {
            position: absolute;
            top: 0;
            bottom: 12px;
            left: 0;
            right: 12px;
        }

        thead {
            background: inherit;
        }

        &--top-header {
            margin: 0;
            position: sticky;
            top: 0;
            left: 0;
            transform-origin: 0 0;
            z-index: $cellZindex + 2;
            will-change: transform;
            transform: translateY(0);
        }

        .ui-table-th--sticky {
            position: sticky;
            top: 0;
            z-index: $cellZindex + 1;
            padding: 0;
        }

        &--has-top-header {
            margin-top: $rowHeight;

            .ui-table-th--sticky {
                top: $rowHeight;
            }
        }

        &--column-wrapper {
            overflow-x: hidden;
            overflow-y: scroll;
            scroll-padding-top: 0;
            -webkit-overflow-scrolling: touch;
            touch-action: pan-y;

            &::-webkit-scrollbar {
                opacity: 0;
            }

            &::-webkit-scrollbar:vertical {
                width: 0;
            }

            &::-webkit-scrollbar-thumb {
                opacity: 0;
            }
        }

        &--column {
            background-color: transparent;
            .ui-table-tr {
                background-color: transparent !important;
            }
            // Value label column
            .ui-table-td {
                color: mat.get-color-from-palette($foreground, text);
                font-size: 16px;
                font-weight: 500;
                // z-index: 3 !important;
                padding: 0 24px;
                @include border(right, $is-dark);
            }
            .ui-table-td--name {
                background-color: $bg-color !important;
            }
            .ui-table-td--footer:empty {
                display: none;
            }
            .ui-table-th--overlay {
                background-color: transparent !important;
            }
        }

        &-tr {
            height: $rowHeight;
            position: relative;

            &--header {
                &:empty {
                    display: none;
                }
            }

            // Handle borders in and near group row
            &--group {
                .mat-mdc-cell.ui-table-td {
                    border-top: none;
                    border-bottom: none;
                }

                &:last-child {
                    .mat-mdc-cell.ui-table-td {
                        @include border(bottom, $is-dark);
                    }
                }

                & + .ui-table-tr {
                    .mat-mdc-cell.ui-table-td {
                        @include border(top, $is-dark);
                    }
                }
            }

            &--readonly {
                .ui-table-td:not(.mat-column-name) {
                    pointer-events: none;
                }
            }

            &:empty {
                // height: 0;
            }

            &--double {
                height: $rowHeight * 2;
            }

            &--changed,
            &--changed > * {
                color: $changes-color;
            }

            &--deleted {
                text-decoration: line-through;
            }

            &.mat-row:nth-child(even) {
                background-color: mat.get-color-from-palette($background, card);
            }

            &:first-child {
                .mat-mdc-cell {
                    border-top: none;
                }
            }
        }

        &-th {
            color: mat.get-color-from-palette($foreground, text);
            font-size: 14px;
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: 1;
            z-index: $cellZindex + 1;
            @include border(left, $is-dark);

            &:first-child {
                border-left: none;
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            &--active {
                @if $is-dark {
                    color: mat.get-color-from-palette($primary, A400);
                } @else {
                    color: mat.get-color-from-palette($primary);
                }
            }

            &--hidden {
                display: none;
            }

            // Filter header
            &--filter {
                position: relative;
                z-index: $cellZindex !important;
                font-size: 16px;
                padding: 0;
                text-align: left !important;
                height: inherit;

                @include border(right, $is-dark);
                @extend %theme-color-text;
            }

            &--top-header {
                text-align: left !important;
                font-size: 12px !important;
                @extend %theme-color-text;

                &:not(:nth-child(2)) {
                    @include border(left, $is-dark);
                }
            }

            &__inner {
                display: flex;
                justify-content: flex-start;

                &-content {
                    position: sticky;
                    left: 0;
                    padding: 0 2rem;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            &__dts-icon {
                display: none;
                &.mat-icon {
                    font-size: 16px;
                    vertical-align: middle;
                    margin-top: -4px;
                }
            }

            &--dts .ui-table-th__dts-icon {
                display: inline-block;
            }

            .dts-header {
                display: inline-block;
                font-size: 16px;
                vertical-align: middle;
                margin-top: -4px;
            }
        }

        &-th,
        &-td {
            text-align: center;
            min-width: 100px;
            padding: 0;
            box-sizing: border-box;
            // fix default material style
            &.mat-mdc-cell:not(.mat-column-name):first-of-type {
                padding-left: unset;
            }

            @if $is-dark {
                background: mat.get-color-from-palette($primary, 600);
            }

            &--invisible {
                z-index: unset;
            }

            &.text-left {
                text-align: left;
            }

            &.text-right {
                text-align: right;
            }
        }

        &-th,
        &-td--group,
        &-td--footer {
            background-color: mat.get-color-from-palette($background, card) !important;
        }

        &-td {
            background-color: inherit;
            border-top: none;

            @include border(bottom, $is-dark);
            @include border(left, $is-dark);

            &:first-child {
                border-left: none;
            }

            &.mat-value-cell {
                &:last-of-type {
                    padding-right: 0;
                }
            }

            &--active {
                @if $is-dark {
                    background-color: #322c61 !important;
                } @else {
                    background-color: #f3effa !important;
                }
            }

            &--has-value {
                z-index: $cellZindex;
            }

            &--disabled {
                // pointer-events: none; // TODO: decide if should be disabled on this TOP level
            }

            &--overlay {
                pointer-events: none;
                border: none;
                background-color: transparent;
                border-right: none !important;
                border-top: none !important;
                border-bottom: none !important;

                &__text {
                    font-size: 14px;
                    font-weight: 500;
                    position: relative;
                    span:not(:empty) {
                        padding: 0 8px;
                        background-color: mat.get-color-from-palette($background, card) !important;
                    }
                }
            }

            &--name {
                // Multiline overflow
                span {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            &--group {
                position: sticky;
                left: 0;
                padding: 0 !important;

                &-cell {
                    box-sizing: border-box;
                    padding: 0 24px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 15px;
                    font-weight: normal;
                    height: $rowHeight;

                    &:after {
                        content: '\e5cf';
                        font-family: 'Material Icons';
                        font-size: 24px;
                        color: mat.get-color-from-palette($foreground, text);
                        transition: all 0.2s ease;
                    }

                    &--collapsed {
                        &:after {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }

            &--group,
            &--add {
                @include border(right, $is-dark);
            }

            &--add {
                padding-left: 24px;
                padding-right: 24px;
            }

            &__icon {
                &.mat-icon {
                    width: 22px;
                    height: 22px;
                    font-size: 22px;
                    line-height: 22px;
                }
            }

            &__icon-add {
                color: $addIconColor;
                vertical-align: middle;
            }

            &--menu-trigger {
                position: absolute;
                width: inherit;
                height: inherit;
                pointer-events: none;
            }

            &--menu-button {
                width: 48px;
                max-width: 48px;
                padding: 0;
                text-align: center;
            }

            // INFO: node_modules/@angular/material-experimental/popover-edit/_popover-edit-theme.scss

            &.mat-popover-edit-cell {
                &::after {
                    display: none;
                }
            }

            .mat-row-hover-content {
                color: mat.get-color-from-palette($primary, 600);
            }

            .mat-eit-pane {
                padding: 0;
                background-color: transparent !important;
            }

            &--edit-form {
                .ui-dynamic-input-wrapper {
                    min-width: unset;
                }

                &--borderless {
                    background-color: transparent !important;
                }
            }

            &.mat-ripple {
                cursor: pointer;
            }
        }

        // allow last empty column to resize properly
        &--empty {
            width: 400px;
        }

        &--virtual-scroll {
            .ui-empty-state {
                left: 0;
                transform: translateX(-200px);
            }
        }

        &--striped {
            tr.mat-row:nth-child(odd) {
                @if $is-dark {
                    background-color: lighten(mat.get-color-from-palette($background, card), 2);
                } @else {
                    background-color: darken(mat.get-color-from-palette($background, card), 2);
                }
            }
        }

        &--borderless {
            td {
                border: none;
            }
        }

        &__menu-panel {
            min-width: 180px;
        }

        &-filter {
            height: inherit;

            &__select {
                position: relative;
                display: block;
                height: inherit;
            }

            .mat-mdc-select-trigger {
                padding: 0 24px;
                height: inherit;
            }

            .mat-mdc-select-value-text {
                font-size: 16px;
                width: calc(100% - 4px);
                display: inline-block;
            }

            &__label {
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: space-between;

                &-suffix {
                    font-size: smaller;
                    color: mat.get-color-from-palette($foreground, secondary-text);
                    float: right;
                }
            }

            &__placeholder {
                position: absolute;
                display: inline-flex;
                justify-content: space-between;
                width: calc(100% - 72px);
                padding: 0 24px;
                color: mat.get-color-from-palette($foreground, secondary-text);
            }

            &__panel {
                .mat-checkbox-checkmark-path {
                    @if $is-dark {
                        stroke: white !important;
                    }
                }

                .mat-checkbox-label {
                    @if not $is-dark {
                        color: rgba(0, 0, 0, 0.87);
                    }
                }

                .mat-mdc-checkbox,
                .mat-pseudo-checkbox::after {
                    color: white;
                }
            }
        }

        &__menu-item {
            min-width: 180px;
            padding-right: 42px;

            &:after {
                content: '';
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }

            &.mat-mdc-menu-item[disabled]::after {
                top: 50%;
                left: auto;
                right: 16px;
            }

            &--edit {
                &:after {
                    background-color: $primaryBackgroundColor;
                }
            }

            &--remove {
                &:after {
                    background-color: mat.get-color-from-palette($accent);
                }
            }

            &--warning {
                &:after {
                    background-color: mat.get-color-from-palette($warn);
                }
            }
        }

        &__empty {
            position: absolute;
            top: 90px;
        }
    }
    // paginator scroll buttons
    .ui-vhs-pager {
        width: 180px;
        height: 40px;
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        display: flex;
        justify-content: space-between;
        z-index: 20;
    }

    // HELPER CLASSESS TO SET COLUMN WIDTH
    .w-full {
        width: 100%;
    }
    .w-75 {
        width: 75px;
    }
    .w-100 {
        width: 100px;
    }
    .w-125 {
        width: 125px;
    }
    .w-150 {
        width: 150px;
    }
    .w-175 {
        width: 175px;
    }
    .w-200 {
        width: 200px;
    }
    .w-250 {
        width: 250px;
    }
    .w-300 {
        width: 300px;
    }

    .cdk-overlay-pane.mat-edit-pane {
        padding: 10px 5px 0 5px;
        height: 50px;

        .mat-edit-lens.ui-table-td--edit-form {
            .mat-form-field-wrapper {
                padding-bottom: 10px;
            }
            .mat-form-field-underline {
                bottom: 10px;
            }
        }

        &.double {
            height: 100px;
        }

        & .ui-table-td--edit-form {
            height: 40px;

            & .mat-mdc-form-field-infix {
                min-height: 30px;
                padding: 0;
            }
        }
    }

    .mat-mdc-card--inside,
    [uivhstableparent] {
        height: 100%;
        padding: 0;
    }

    .mdc-data-table__row:last-child .mat-mdc-cell {
        @if $is-dark {
            border-bottom: 1px solid #403c69 !important;
        } @else {
            border-bottom: 1px solid #ebebeb !important;
        }
    }

    .mat-mdc-header-cell {
        font-size: 14px;
        @extend %theme-color-text-header;
        overflow: visible;
    }
}

// NOTE: fix for edit panes for tables(hide mat-edit-pane element when lock screen is opened)
.mona-lock-screen--opened .cdk-overlay-pane.mat-edit-pane {
    display: none !important;
}

.keyboard-container {
    padding-bottom: $mona-keyboard-height;
}
