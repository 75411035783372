@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-mic-button-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    // Adds themed keyframe: https://stackoverflow.com/questions/57419583/how-to-create-theme-based-css-animations-in-angular-material
    $keyframes-name: map-get($theme, keyframes);

    .mona-mic-button {
        color: #fff;
        background-color: mat.get-color-from-palette($primary, 500);

        @if $is-dark {
            color: mat.get-color-from-palette($primary, 500);
            background-color: rgba(255, 255, 255, 0.7);
        }

        &--pulsing {
            animation: $keyframes-name 2.2s infinite;
        }
    }
}
