@use '@angular/material' as mat;


// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-relative-contacts-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $warn: map-get($theme, warning);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $textColor: if($is-dark, rgba(255, 255, 255, 0.72), rgba(0, 0, 0));
    $changes-color: map-get($theme, changes-color);
    $overdue-color: mat.get-color-from-palette($warn, 900);

    %theme-color-text {
        @if $is-dark {
            color: rgba(255, 255, 255, 0.32);
        } @else {
            color: rgba(0, 0, 0, 0.32);
        }
    }

    %theme-color-fill {
        @if $is-dark {
            fill: rgba(255, 255, 255, 0.32);
        }
    }

    .treatment-interventions__empty-state {
        button {
            display: none;
        }
    }

    .no-data-state {
        position: absolute;
        width: 100%;
        top: 0;
        margin: auto;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
