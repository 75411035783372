@use '@angular/material' as mat;

:host {
    .wl-back {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 500;
        opacity: 72%;
        display: flex;
        align-items: center;

        mat-icon {
            margin-right: 10px;
        }
    }

    .workflows-container {
        margin: 20px 0;
        border-radius: 8px;
        overflow: auto;
        // layout-container paddings = 64px, workflows header = 77px, back button = 24px
        // workflows container margin = 40px, workflow details footer = 36px
        max-height: calc(100vh - 64px - 77px - 24px - 40px - 36px);

        .workflow {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:focus {
                outline: none;
            }

            &.wf-border {
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }

            .workflow-info {
                display: flex;
                align-items: center;
                position: relative;

                mat-icon {
                    margin-right: 18px;
                }

                .removed-line {
                    position: absolute;
                    border-bottom: 1px solid #00000052;
                    width: 100%;
                }

                .complete {
                    color: #4caf50;
                }

                .incomplete {
                    color: #ff8f00;
                }

                .deleted {
                    color: #00000052;
                }
            }
        }
    }
}

@mixin workflow-list-theme($theme) {
    $background: map-get($theme, background);

    .workflows-container {
        background-color: mat.get-color-from-palette($background, card);
    }
}

.btn-loader-icon {
    animation: rotate 1s infinite linear;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
    0% {
        transform: rotate(0deg);
    }
}
