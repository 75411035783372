@use '@angular/material' as mat;

@mixin custom-button-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    $white: map-get($theme, white);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mat-mdc-flat-button,
    .mdc-button--raised,
    .mdc-button {
        border-radius: 50px;
        text-transform: uppercase;
        padding: 0 24px;
        letter-spacing: normal;
        z-index: 0;

        .mat-icon {
            margin-right: 8px;
            font-size: 24px;
            height: 24px;
            width: 24px;
        }

        &:disabled,
        &[disabled='true'],
        &[aria-disabled='true'] {
            .mat-mdc-button-ripple.mat-ripple,
            .mat-mdc-button-focus-overlay {
                pointer-events: all;
            }
            pointer-events: all;
        }
    }

    .mdc-button {
        @if $is-dark {
            color: $white !important;
        }
    }

    .mdc-icon-button {
        padding: 0;
        display: flex;
        height: 40px;
        width: 40px;
        align-items: center;
        justify-content: center;

        .mat-mdc-button-touch-target {
            height: 40px;
            width: 40px;
        }

        .mat-icon.mat-icon-inline {
            height: unset;
        }
    }

    .mat-mini-fab .mat-icon {
        color: mat.get-color-from-palette($foreground);
        @if $is-dark {
            color: $white !important;
        }
    }
}
