// Display
.d-block {
    display: block;
}
.d-inline {
    display: inline;
}
.d-inline-block {
    display: inline-block;
}
.d-flex {
    display: flex;
}
.d-inline-flex {
    display: inline-flex;
}
.d-none,
.d-hide {
    display: none !important;
}
.d-visible {
    visibility: visible;
}
.d-invisible {
    visibility: hidden;
}
.text-hide {
    background: transparent;
    border: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    text-shadow: none;
}
.text-assistive {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.op-5 {
    opacity: 0.5;
}

[hidden='false'] {
    display: unset !important;
}

.gap-1 {
    gap: 0.5rem !important;
}

.gap-2 {
    gap: 1rem !important;
}

.gap-3 {
    gap: 1.5rem !important;
}

.gap-4 {
    gap: 2rem !important;
}

.w-25 {
    width: 25%;
}

.w-50 {
    width: 50%;
}

.w-75 {
    width: 75%;
}

.w-100 {
    width: 100%;
}
