@use '@angular/material' as mat;

@mixin app-encounter-info-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $is-dark: map-get($theme, is-dark);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);

    .mona-encounter-info__container {
        @if $is-dark {
            background-color: #262452;
        } @else {
            background-color: #f5f2fa;
        }

        .mona-encounter-info__sidenav-item--subtitle {
            @if $is-dark {
                color: gray;
            } @else {
                color: rgba(0, 0, 0, 0.32);
            }
        }
    }

    .not-assigned-bed {
        background-color: $pdms-background-color-warning;
    }

    .is-review-mode {
        background-color: #2a8eea;
    }

    .is-review-mode-font {
        color: #2a8eea;
    }

    .not-assigned-bed-font {
        color: $pdms-background-color-warning;
    }

    .not-assigned-bed,
    .is-review-mode {
        & .mona-encounter-info__sidenav-item--subtitle {
            color: #000;
        }

        & .mona-encounter-info__sidenav-item--title {
            color: #000;
        }
    }
}
