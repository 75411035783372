@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-usermenu-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $white: map-get($theme, white);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .ui-user-menu-avatar {
        background-color: mat.get-color-from-palette($primary, 50);

        @if $is-dark {
            color: mat.get-color-from-palette($primary, A500);
        } @else {
            color: mat.get-color-from-palette($primary);
        }
    }
}
