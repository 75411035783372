/*
IMPORTANT: Mona styles are provided as SCSS styles so they should be imported in a SCSS file!
*/
@use 'theme';
@import 'variables';

// Set global overrides
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    user-select: none;
}

.spacer {
    flex: 1;
}

.resize-on-keyboard-top {
    position: fixed !important;
    bottom: 384px;
}

// Globally fixing weird tap highlight
button,
a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
