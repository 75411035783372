@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-outputs-table-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);
    $inputTextColor: if($is-dark, white, black);
    $inputBorderColor: if($is-dark, #bfbfbf, #707070);
    $primaryTextColor: if($is-dark, mat.get-color-from-palette($primary, A400), mat.get-color-from-palette($primary));
    $addIconColor: if($is-dark, rgba(255, 255, 255, 0.08), rgba(0, 0, 0, 0.32));

    .app-outputs-table {
        &__add-menu {
            max-height: 300px;
        }

        &__container {
        }

        &__filter-container {
            &__select {
                // Overwrite material style on global level
                .mat-mdc-select-trigger {
                    font-size: 16px;
                }
            }
        }

        &__table {
            width: 100%;
            .ui-table {
                &--overlay,
                &.mat-column-overlayColumn {
                    :not(.mat-column-value--range) {
                        display: none;
                    }
                }
                &-td {
                    &--active ~ * {
                        pointer-events: none;

                        .mat-column-value__icon-add-suggestion {
                            opacity: 0.4;
                        }

                        .mat-column-value__icon-add {
                            display: none;
                        }
                    }

                    &--active {
                        .mat-column-value__icon-add {
                            display: inline-block;
                        }
                    }
                }
            }

            // Value label column
            .mat-mdc-cell.mat-column-name {
                color: mat.get-color-from-palette($foreground, text);
                text-align: left;

                @if $is-dark {
                    border-right: 1px solid #403c69;
                } @else {
                    border-right: 1px solid #ebebeb;
                }
            }
        }

        .mat-mdc-cell .mat-column-value {
            &:before,
            &:after {
                background-color: mat.get-color-from-palette($primary, 300);

                @if $is-dark {
                    background-color: mat.get-color-from-palette($primary, A400);
                }
            }

            &--changed {
                color: $changes-color;
            }
        }

        .mat-column-value {
            &__icon-add {
                color: $addIconColor;

                &.mat-icon {
                    width: 22px;
                    height: 22px;
                    font-size: 22px;
                }
            }

            &:hover {
                .mat-column-value__add {
                    color: rgba(0, 0, 0, 0.64);

                    @if $is-dark {
                        color: rgba(255, 255, 255, 0.64);
                    }
                }
            }

            &--editing {
                z-index: 1;
            }

            &__input {
                display: inline-flex;
                outline: none;
                background-color: transparent;
                color: $inputTextColor;
                border: 2px dashed $inputBorderColor;
                white-space: nowrap;
                font-weight: 500;
                padding: 4px;
                height: 20px;

                &:disabled {
                    color: inherit;
                    border: none !important;
                    padding: 0;
                }

                &--invalid {
                    border: 2px dashed $mona-error;
                }
            }

            &--warning {
                color: mat.get-color-from-palette($warn);
            }

            &--changed {
                color: $changes-color;
            }

            &--removed {
                text-decoration: line-through;
            }

            &--automated {
                pointer-events: none;
            }
        }

        &__add {
            @if $is-dark {
                border-right: 1px solid #403c69;
            } @else {
                border-right: 1px solid #ebebeb;
            }

            &__trigger {
                color: $primaryTextColor;
                display: flex;
                align-items: center;

                @if $is-dark {
                    color: mat.get-color-from-palette($primary, A400);
                } @else {
                    color: mat.get-color-from-palette($primary);
                }
            }
        }
    }
}
