@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-forms-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $white: map-get($theme, white);
    $black: map-get($theme, black);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $input-color: if($is-dark, rgba($white, 0.6), rgba($black, 0.6));

    /* ---------------------------------------------------------------------- */
    /* handle CUSTOM TYPES styles in one place */
    /* ---------------------------------------------------------------------- */
    .mat-form-field-type-ui-date-picker,
    .mat-form-field-type-ui-date-time-picker {
        .mat-form-field-label-wrapper {
            border-top: 10px solid transparent;
        }

        mat-datepicker-toggle button.mat-focus-indicator.mdc-icon-button.mat-mdc-button-base {
            transform: translateY(-10px);
        }

        .mat-icon {
            vertical-align: unset;
        }
    }

    .mat-form-field-type-ui-date-picker,
    .mat-mdc-form-field-type-ui-date-picker,
    .mat-mdc-form-field-type-ui-date-time-picker {
        .mdc-floating-label--float-above {
            padding-bottom: 4px;
        }

        .mat-datepicker-input {
            padding-top: 12px;
        }

        .mat-mdc-form-field-infix {
            padding-top: 8px;
            padding-bottom: 0px;
        }
    }

    .ui-date-time__time-input {
        margin-top: 16px;
    }

    .mat-form-field-type-contenteditable {
        [contenteditable]:focus-visible {
            outline: none;
        }
    }

    .time-picker__label {
        color: $input-color;
    }

    .cdk-focused .time-picker__label {
        color: #9575cd;
    }
}
