@use '@angular/material/index' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin add-prescription-set-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $textColor: if($is-dark, rgba(255, 255, 255, 0.72), rgba(0, 0, 0));
    $borderColor: if($is-dark, rgba(255, 255, 255, 0.12), rgba(0, 0, 0, 0.12));

    .add-prescription-set {
        .navigation-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 10px;
            font-size: 16px;
        }

        .disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        .mat-mdc-tab-header {
            display: none;
        }

        .mat-tab-group,
        .mat-tab-body-wrapper {
            height: 100%;
        }

        mat-list-item .mdc-list-item__content {
            justify-content: space-between;
        }

        ::ng-deep mona-prescription-set-list {
            .mat-mdc-list-item {
                height: 70px;

                .mona-prescription-sets__card-icon {
                    color: #000;
                }

                &:first-child {
                    border-bottom: 1px solid $borderColor;
                }
            }
        }
    }
    .prescription-set {
        &__title {
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 32px;
        }

        &__content-wrapper {
            border: 1px solid $borderColor;
            border-radius: 8px;
            overflow: auto;
            padding-bottom: 1rem;

            ::ng-deep {
                .mona-medication-table {
                    &__wrapper {
                        height: auto;
                    }
                }
            }
        }
    }

    .add-prescription-set-btn {
        margin-top: 40px;
        float: right;
    }

    .prescription-set-list-container {
        .mat-mdc-list-item {
            height: 70px;

            .mona-prescription-sets__card-icon {
                @if $is-dark {
                    color: #fff;
                } @else {
                    color: #000;
                }
            }
        }
    }
}
