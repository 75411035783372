@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-signin-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $white: map-get($theme, white);
    $title-color: if($is-dark, $white, mat.get-color-from-palette($primary));

    .mona-auth-dialog {
        width: 725px;

        &--signin {
            // smaller dialog window
            height: 480px;

            .mona-auth-form__title span {
                font-size: 45px;
                font-weight: 700;
            }
        }

        &--verify {
            // larged dialog window
            max-height: 750px;
            min-height: 650px;
            height: auto;

            .mona-auth-form__title span {
                font-size: 36px;
                font-weight: 300;
            }
        }

        .mat-dialog-container {
            padding: 40px 40px 24px;
            position: relative;

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            }
        }

        &__container {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .mat-dialog-content {
                width: 100%;
            }
        }

        &__title {
            color: $title-color;
        }

        .mona-auth-form__title {
            color: $title-color;
            line-height: normal;
        }

        &__close {
            opacity: 0.54;
            position: absolute;
            top: 10px;
            right: 12px;
        }

        &__back {
            text-transform: lowercase;
            margin-bottom: 32px;
            padding: 0;
        }

        .mat-mdc-tab-header {
            display: none;
        }
    }
}
