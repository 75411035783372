@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-care-check-form-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .app-care-checks-form {
        &__actions {
            display: flex;
            justify-content: flex-end;
        }

        &-row {
            &:not(:last-of-type) {
                padding-bottom: 4px;
                margin-bottom: 30px;
                @if $is-dark {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
                } @else {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
                }
            }
        }

        &__action-btn {
            &.mat-primary {
                @if $is-dark {
                    background-color: mat.get-color-from-palette($accent);
                    color: rgba(255, 255, 255, 0.87);
                }
            }
        }

        &__delete {
            &.mat-primary {
                @if $is-dark {
                    background-color: mat.get-color-from-palette($accent);
                    color: rgba(0, 0, 0, 0.87);
                }
            }
        }
    }
}
