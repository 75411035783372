@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-wards-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mona-wards {
        &__nav {
            height: inherit;
            width: 100%;

            .mat-tab-body-wrapper {
                flex-grow: 1;
            }

            .mat-mdc-tab-header {
                margin-bottom: 52px;
                width: 100%;
            }

            .mat-mdc-tab {
                opacity: 1;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 500;
                max-width: 204px;
                width: 204px;

                &.mdc-tab--active .mdc-tab__text-label {
                    @if $is-dark {
                        color: mat.get-color-from-palette($primary, A400);
                    } @else {
                        color: mat.get-color-from-palette($primary, 500);
                    }
                }

                .mdc-tab__text-label {
                    @if $is-dark {
                        color: rgba(255, 255, 255, 0.72);
                    } @else {
                        color: #000;
                    }
                }
            }
        }
        &__spinner {
            margin-left: 96px;
            height: inherit;
            width: 100%;

            &-wrapper {
                width: inherit;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    /* ---------------------------------------------------------------------- */
    /* BED theme */
    /* ---------------------------------------------------------------------- */

    .mona-ward-bed {
        transition: 0.2s;

        .mona-ward-bed__name {
            @if $is-dark {
                // color: rgba(255, 255, 255, 0.64);
                background-color: mat.get-color-from-palette($primary, 500);
            } @else {
                color: white;
                background-color: mat.get-color-from-palette($primary, 500);
            }
        }

        @if $is-dark {
            color: rgba(255, 255, 255, 0.64);
            background-color: mat.get-color-from-palette($primary, 700);
        } @else {
            background-color: #fff;
            color: #000;
        }

        &:not(.mona-ward-bed--empty) {
            @include mat.elevation(1);
            margin-bottom: 2px;
            &:not(.mona-ward-bed--no-elevation):hover {
                @include mat.elevation(3);
            }
        }

        &--loading {
            opacity: 0.5;
        }

        &--empty {
            opacity: 0.5;
            // background: transparent;

            @if $is-dark {
                border: 5px dashed rgba(255, 255, 255, 0.32);
                color: rgba(255, 255, 255, 0.32);
            } @else {
                color: rgba(0, 0, 0, 0.32);
                border: 5px dashed rgba(0, 0, 0, 0.32);
            }

            &:hover {
                opacity: 1;
            }
        }

        &--highligted {
            opacity: 1;
        }

        &__patient {
            @if $is-dark {
                color: rgba(255, 255, 255, 0.72);
            } @else {
                color: rgba(0, 0, 0, 0.72);
            }
        }

        &__icon {
            @if $is-dark {
                color: rgba(255, 255, 255, 0.16);
            } @else {
                color: rgba(0, 0, 0, 0.16);
            }
        }
    }
}
