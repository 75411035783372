@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-entry-control-step-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .app-entry-control-step {
        .mat-radio-label-content {
            padding-left: 16px;
        }

        &-form-row {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -24px;
        }

        &-form-column {
            flex: 1 1 auto;
            padding: 0 24px;
        }

        &-form-group {
            display: flex;
            align-items: flex-start;
            min-height: 56px;

            &-report {
                textarea {
                    min-height: 280px;
                    resize: none;
                }
            }
        }

        &-form-label {
            width: 180px;
            font-weight: 400;
            font-size: 14px;

            &--changed {
                color: $changes-color;
            }
        }

        &-form-field {
            &-cuff {
                max-width: 160px;
                width: 160px;
                margin-top: -8px;
            }
        }

        &-radio-group {
            display: flex;
            margin-top: 4px;

            &__option:not(:nth-last-child(1)) {
                margin-right: 40px;
            }
        }

        &-section {
            &__title {
                &--changed {
                    color: $changes-color;
                }
            }
        }

        &-confirmation {
            color: #ffffff;

            &--disabled {
                opacity: 0.75;
                pointer-events: none;
            }

            &--confirmed {
                background: mat.get-color-from-palette($success);
            }

            &--not-confirmed {
                background: mat.get-color-from-palette($accent);
            }

            .mat-icon {
                margin-right: 18px;
            }

            .mat-mdc-checkbox {
                pointer-events: none;
                margin-right: auto;
            }

            .mdc-checkbox__background {
                border-color: #fff !important;
            }

            .mdc-label {
                font-weight: 500;
                color: #fff;
            }

            .mat-checkbox-frame {
                border-color: #ffffff;
            }

            .mdc-checkbox {
                margin-right: 18px;
            }
        }
    }
}
