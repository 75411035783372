@use '@angular/material' as mat;

.ui-fixed-snakbar-overlay-pane {
    position: absolute;
    transition: opacity 0.2s;
    opacity: 1;

    .ui-drawer-layout-sidenav--closed & {
        opacity: 0;
        border: none !important;
    }
}

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin custom-mat-snackbar-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $max-snack-bar-width: 300px;

    .mdc-tooltip {
        max-width: 250px !important;

        &__surface {
            max-width: 250px !important;
            font-size: 14px !important;
            letter-spacing: 0px;
        }
    }

    .mdc-snackbar {
        .mat-mdc-snack-bar-label {
            padding: 0;
        }

        &__surface {
            padding: 0;
            margin: 20px;
        }
    }

    // Absolute positioned snackbar attached to some parent element
    .ui-fixed-snakbar-overlay-pane {
        .mat-mdc-snack-bar {
            &-container {
                margin: 0;
                min-width: $max-snack-bar-width;
                width: $max-snack-bar-width;

                &.__ui-custom-snackbar {
                    // FIXME
                    background-color: mat.get-color-from-palette($background, card);
                    color: mat.get-color-from-palette($foreground, card);
                }
            }
            .mdc-button {
                color: mat.get-color-from-palette($primary, 700) !important;
                @if $is-dark {
                    color: white !important;
                }
            }
        }
    }
}
