@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-wifi-connect-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $warn: map-get($theme, warning);

    .wrong-passphrase {
        color: mat.get-color-from-palette($warn);
    }
}
