@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin procedure-prescriptions-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .app-procedure-prescriptions {
        &__wrapper {
            height: calc(100% - 110px);
            min-height: 75vh;
            overflow-y: overlay;
            overflow-x: auto;
            padding-top: 40px;

            .ui-table {
                table-layout: auto !important;

                .new-prescription {
                    color: white;

                    @if $is-dark {
                        background-color: mat.get-color-from-palette($primary, A400);
                    } @else {
                        background-color: mat.get-color-from-palette($primary);
                    }
                }
                .row-deleted {
                    .ui-table-td.mat-mdc-cell {
                        color: gray; // Todo: create var with text-disabled color
                        mat-icon,
                        .changes-asterisk {
                            text-decoration: none;
                        }
                    }
                }
                .row-changes {
                    td.ui-table-td.mat-mdc-cell:not([class*='ui-table-td--menu-button']) {
                        color: mat.get-color-from-palette($accent);
                    }

                    .new-prescription {
                        background-color: mat.get-color-from-palette($accent);
                    }
                }

                .row-deleted.row-changes {
                    .ui-table-td.mat-mdc-cell {
                        text-decoration: line-through;
                    }
                }
            }

            &__last-change-by-role {
                &--doctor {
                    color: mat.get-color-from-palette($success);
                }

                &--nurse {
                    color: mat.get-color-from-palette($accent);
                }
            }

            &__confirmation {
                color: #ffffff;

                &--disabled {
                    opacity: 0.75;
                    pointer-events: none;
                }

                &--confirmed {
                    background: mat.get-color-from-palette($success);
                }

                &--not-confirmed {
                    background: mat.get-color-from-palette($warn);
                }

                .mat-icon {
                    margin-right: 18px;
                }

                .mat-mdc-checkbox {
                    pointer-events: none;
                    margin-right: auto;
                }

                .mdc-checkbox__background {
                    border-color: #fff !important;
                }

                .mdc-label {
                    font-weight: 500;
                    color: #fff;
                }

                .mat-checkbox-frame {
                    border-color: #ffffff;
                }

                .mdc-checkbox {
                    margin-right: 18px;
                }
            }
        }

        &__category-table {
            margin-bottom: 20px;

            mat-expansion-panel {
                box-shadow: none;
            }
            mat-expansion-panel-header {
                height: 60px;
            }
            mat-panel-title.mat-expansion-panel-header-title {
                font-size: 20px;
                line-height: 24px;
                mat-icon {
                    margin-right: 15px;
                }
            }

            .cell-with-icon {
                display: flex;
                align-items: flex-start;
                mat-icon:first-child {
                    margin-right: 15px;
                    width: 24px;
                    height: 24px;
                    min-width: 24px;
                    min-height: 24px;
                }
                .changes-asterisk {
                    margin-right: 15px;
                    font-size: 32px;
                    display: flex;
                    width: 24px;
                    height: 24px;
                    padding-top: 3px;
                    justify-content: center;
                    align-items: flex-end;

                    // border: 2px solid mat.get-color-from-palette($accent);
                    // border-radius: 4px;
                    // color: mat.get-color-from-palette($accent);
                }
            }

            ::ng-deep {
                .ui-table-wrapper td.ui-table-td.mat-mdc-cell {
                    border: none;
                    text-align: left;
                }

                th.mat-mdc-header-cell {
                    border-left: 0;
                }

                tr.ui-table-tr td.ui-table-td:first-child {
                    padding-left: 0;
                }

                .ui-table-wrapper tr.ui-table-tr.mat-row:nth-child(odd) {
                    background: none;
                }

                tr.ui-table-tr.ui-table-tr--header.mat-mdc-header-row th {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.32);
                }

                td.ui-table-td {
                    vertical-align: top;
                    padding-top: 10px;
                }

                td.ui-table-td.delete-cell,
                th.ui-table-th.delete-cell {
                    padding-right: 10px;
                    border-left: none;
                    width: 75px;
                    display: table-cell;
                }
            }
        }

        &__table {
            height: calc(100% - 4px);
            margin: 0 auto;

            .mat-mdc-cell,
            .mat-mdc-header-cell,
            .mat-mdc-footer-cell {
                box-sizing: border-box;
                padding: 0 18px;
            }

            .mat-mdc-footer-row,
            .mat-mdc-footer-cell {
                &--hide {
                    display: none;
                }
            }

            .mat-mdc-header-cell {
                font-size: 12px;
            }

            .mat-mdc-header-cell.mat-column-filter,
            .mat-column-description {
                width: 280px;
                max-width: 280px;
                min-width: 280px;
                padding-right: 8px;
                text-align: left;

                &.mat-mdc-footer-cell {
                    justify-content: flex-start;
                }
            }

            .mat-column-frequency {
                width: 136px;
                max-width: 136px;
                min-width: 136px;
                text-align: left;
            }

            .mat-column-endDate {
                width: 160px;
                max-width: 160px;
                min-width: 160px;
                text-align: left;

                input {
                    max-width: 100px;
                }
            }

            .mat-column-instructions {
                width: 323px;
                max-width: 323px;
                text-align: left;
            }

            .mat-column-groupHeader {
                padding: 0;
            }

            // Filter Header
            .mat-mdc-header-cell.mat-column-filter {
                font-size: 16px;
                padding: 0;
            }
        }

        &__group {
            width: 280px;
            padding: 0 18px;
            box-sizing: border-box;
            min-height: 48px;
            display: flex;
            align-items: center;
            cursor: pointer;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            justify-content: space-between;

            &-expand {
                font-size: 20px;
                width: 20px;
                height: 20px;
                line-height: 20px;
            }
        }

        &__add {
            font-size: 18px;
            width: 18px;
            height: 18px;
            line-height: 18px;
            margin-right: 8px;
        }

        &__input {
            max-width: 100%;
            outline: none;
            -webkit-appearance: none;
            text-align: center;
            font-size: 14px;
            height: 36px;
            box-sizing: border-box;

            &--textarea {
                width: 280px;
                text-align: left;
                padding: 8px 0;
                box-sizing: content-box;
                height: auto;
            }
        }

        &__mark {
            margin-right: 16px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none !important;
        }

        &__spinner {
            margin: 80px auto 0;
        }

        &__delete {
            margin-left: auto;

            &-icon {
                font-size: 18px;
                width: 18px;
                height: 18px;
                line-height: 18px;
            }
        }

        &__filter-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            padding: 24px;
            padding-right: 28px; // +4px for the dropdown icon
            cursor: pointer;
        }

        &__confirmation {
            margin: 0 -18px;
            padding: 0 18px;
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
        }
    }
}
