@use '@angular/material' as mat;
// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-task-list-group-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);
    $overdue-color: mat.get-color-from-palette($warn, 900);
    $actual-color: #673ab7;
    $completed-color: #4caf50;
    $primary-text: map-get($theme, primary-text);
    $secondary-text: map-get($theme, secondary-text);
    $edit-actual: mat.get-color-from-palette($primary, 100);
    $edit-overdue: mat.get-color-from-palette($warn, 800, 0.5);

    .task-list-group {
        &__section {
            border: 2px solid $actual-color;
            &__header {
                color: white;
                background-color: $actual-color;

                mat-checkbox.mat-mdc-checkbox {
                    height: 40px;
                    display: flex;

                    .mdc-checkbox .mdc-checkbox__background {
                        border-color: white !important;
                    }

                    .mdc-label {
                        color: white;
                        font-weight: 500;
                        letter-spacing: normal;
                        font-size: 16px;
                    }

                    .mdc-checkbox__ripple {
                        display: none;
                    }

                    &.is-completed.mat-mdc-checkbox-checked .mdc-checkbox .mdc-checkbox__background {
                        border-color: $completed-color !important;
                        background-color: $completed-color;
                    }
                }
            }
        }

        &__timeline__timestamp {
            background-color: $actual-color;
            color: white;

            &:after {
                background: $actual-color;
            }
        }

        &__content {
            tr {
                transition: 0.3s;
                &.task-in-edit {
                    background-color: $edit-actual;
                }
            }

            table.mat-mdc-table {
                td.mat-column-category {
                    color: $secondary-text;
                }

                td.mat-column-edit {
                    width: 50px;
                }

                tr.cross {
                    td.mat-mdc-cell:not(:nth-last-child(1)) {
                        &,
                        & > * {
                            text-decoration: line-through;
                        }
                    }
                }

                tr.has-changes {
                    td.mat-mdc-cell:not(:nth-last-child(1)),
                    mat-checkbox.mat-mdc-checkbox .mdc-label {
                        color: $changes-color;
                    }
                }

                td .mdc-icon-button,
                td .mat-mdc-button-touch-target {
                    width: 40px;
                    height: 40px;
                }

                mat-checkbox.mat-mdc-checkbox.mat-mdc-checkbox-checked {
                    .mdc-checkbox__background {
                        border-color: $changes-color !important;
                    }

                    &.cross {
                        .mdc-checkbox__background {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FFFFFF'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/%3E%3C/svg%3E");
                            background-position: center;
                            background-size: 20px;

                            .mdc-checkbox__checkmark {
                                display: none;
                            }
                        }
                    }
                }

                tr.is-completed {
                    td.mat-mdc-cell:not(:nth-last-child(1)) {
                        color: $completed-color !important;

                        mat-checkbox.mat-mdc-checkbox.mat-mdc-checkbox-checked {
                            .mdc-label {
                                color: $completed-color !important;
                            }

                            .mdc-checkbox .mdc-checkbox__background {
                                border-color: $completed-color !important;
                                background-color: $completed-color !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .overdue {
        .task-list-group__timeline__timestamp {
            background-color: $overdue-color;
            color: white;

            &:after {
                background-color: $overdue-color;
            }
        }
        .task-list-group__section {
            border: 2px solid $overdue-color;

            &__header {
                background-color: $overdue-color;
                .mdc-checkbox .mdc-checkbox__background {
                    border-color: white !important;
                }

                .mat-mdc-checkbox-checked .mdc-checkbox .mdc-checkbox__background {
                    border-color: $changes-color !important;
                }

                .mat-mdc-checkbox .mdc-label {
                    color: white;
                }
            }

            mat-icon {
                color: $primary-text;
            }
        }

        table.mat-mdc-table {
            tr.task-in-edit {
                background-color: $edit-overdue;
            }
        }
    }

    .edit-sign svg path:last-child {
        @if $is-dark {
            fill: #fff;
            stroke: #000;
        }
    }
}
