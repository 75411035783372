@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-entry-control-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .app-entry-control {
        .mat-horizontal-stepper-wrapper {
            height: inherit;
        }

        .mat-step-header {
            &[aria-labelledby='disabled'] {
                pointer-events: none !important;
                cursor: not-allowed;
                opacity: 0.6;
            }

            .mat-step-icon-selected {
                @if $is-dark {
                    background-color: mat.get-color-from-palette($accent);
                    color: rgba(0, 0, 0, 0.87);
                }
            }
        }

        .mat-stepper-horizontal {
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .mat-horizontal-content-container {
            overflow: auto;
            overflow-x: hidden;
            padding: 0 56px 60px;

            @extend .scrollbar-visible;
        }

        &__spinner {
            background: transparent;
        }
    }
}
