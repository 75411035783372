@use '@angular/material' as mat;
// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-telemedicine-feature-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mona-telemedicine-setup {
        &__title,
        &__activate-hint,
        &__code-hint {
            color: rgba(0, 0, 0, 0.72);

            @if $is-dark {
                color: #fff;
            }
        }
    }

    .mona-telemedicine {
        &__main-column {
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 2px 2px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            } @else {
                background-color: #fff;
            }
        }

        &__info-column {
            background-image: url('^assets/images/telemedicine.svg');
            background-position: 32px bottom;
            background-repeat: no-repeat;
        }

        &__title {
            @if $is-dark {
                color: rgba(255, 255, 255, 0.72);
            } @else {
                color: #000;
            }
        }

        &__description {
            @if $is-dark {
                color: rgba(255, 255, 255, 0.32);
            } @else {
                color: rgba(0, 0, 0, 0.32);
            }
        }

        &__rfid {
            border: 1px solid rgba(0, 0, 0, 0.16);

            @if $is-dark {
                border: 1px solid rgba(255, 255, 255, 0.16);
            }
        }

        &__rfid-title {
            color: mat.get-color-from-palette($primary);

            @if $is-dark {
                color: #fff;
            }
        }

        &__warning {
            &-title {
                color: mat.get-color-from-palette($warn);
            }
        }
    }

    .mona-call {
        background-color: #000;
        color: #fff;

        &__alone,
        &__partner_video_off {
            background-color: #1a1740;
        }

        &__camera,
        &__camera-off {
            background-color: #1a1740;
            border: 5px solid rgba(255, 255, 255, 0.72);
            color: #fff;
        }

        &__callee {
            color: rgba(0, 0, 0, 0.72);
            background-color: #fff;
        }

        &__volume {
            &-inner {
                background-color: rgba(0, 0, 0, 0.12);
            }

            &-button {
                background-color: rgba(0, 0, 0, 0.12);
                color: #fff;

                &:disabled {
                    .mat-button-wrapper {
                        color: rgba(255, 255, 255, 0.72);
                    }
                }
            }

            .mat-slider-wrapper {
                background-color: rgba(255, 255, 255, 0.6);
            }
        }
    }
}
