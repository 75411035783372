@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-overlay-container-theme($theme) {
    // Extract the palettes you need from the theme definition.
    // $config: mat.get-color-config($theme);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    $popover-radius: 4px;

    $drawer-background-color: mat.get-color-from-palette($background, dialog);
    $drawer-container-background-color: mat.get-color-from-palette($background, background);
    $drawer-push-background-color: mat.get-color-from-palette($background, dialog);

    .ui-overlay-container {
        // background-color: #ffffff;
        background: mat.get-color-from-palette($background, dialog);
        color: mat.get-color-from-palette($foreground, text);
        // box-shadow: 0 3px 14px 2px rgba(158, 158, 158, 0.12), 0 8px 10px 1px rgba(158, 158, 158, 0.14), 0 5px 5px -3px rgba(158, 158, 158, 0.2);
        @include mat.elevation(12);
        border-radius: $popover-radius;
    }
}
