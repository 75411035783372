@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin custom-scrollbars-theme($theme, $class: 'scrollbar-visible') {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    // make invisible
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .#{$class}::-webkit-scrollbar-track {
        @if $is-dark {
            background: rgba(255, 255, 255, 0.32);
        } @else {
            background-color: #ede7f6;
        }
    }

    .#{$class}::-webkit-scrollbar {
        background-color: mat.get-color-from-palette($primary);

        &:vertical {
            width: $scrollbar-size;
        }

        &:horizontal {
            height: $scrollbar-size;
        }
    }

    .#{$class}::-webkit-scrollbar-thumb {
        background-color: mat.get-color-from-palette($primary);
        border-radius: 8px;
    }

    body {
        scrollbar-color: mat.get-color-from-palette($primary) mat.get-color-from-palette($primary, 300);
    }
}
