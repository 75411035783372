@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin custom-color-palettes-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    ///////////// Make additional color palettes available to material components

    .mat-success {
        background-color: mat.get-color-from-palette($success);
        color: mat.get-color-from-palette($success, default-contrast);
    }
}
