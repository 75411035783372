button[uiButtonProgress],
button[uiButtonLoading] {
    overflow: hidden;
    position: relative;
}

.ui-button-progress-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: black;
    opacity: 0.1;
    pointer-events: none;
}

.ui-button-loading {
    .mat-button-wrapper {
        visibility: hidden;
    }

    .mat-mdc-progress-spinner {
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
    }
}
