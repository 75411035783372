@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin procedures-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);
    $primary-text: map-get($theme, primary-text);
    $primaryBackgroundColor: if(
        $is-dark,
        mat.get-color-from-palette($primary, A400),
        mat.get-color-from-palette($primary, 300)
    );
    $addIconColor: if($is-dark, rgba(255, 255, 255, 0.08), rgba(0, 0, 0, 0.32));

    .mona-procedures {
        &__add-menu {
            max-height: 300px;
        }

        .ui-table {
            &-tr {
                &.mat-mdc-footer-row {
                    display: none;
                }
            }
            &-td {
                &--active {
                    .mat-column-value__icon-add {
                        display: none;
                    }
                }

                &--disabled {
                    pointer-events: none;
                    .mat-column-value__icon-add-suggestion {
                        opacity: 0.4;
                    }
                }

                .mat-column-value--disabled-before-range,
                .mat-column-value--disabled-after-range {
                    width: 100%;
                    height: 56px;
                    max-height: 100%;
                    background-color: rgba(0, 0, 0, 0.09);
                }
            }
        }

        .mat-mdc-cell .mat-column-value {
            &:before,
            &:after {
                background-color: mat.get-color-from-palette($primary, 300);

                @if $is-dark {
                    background-color: mat.get-color-from-palette($primary, A400);
                }
            }

            color: mat.get-color-from-palette($success, 500);

            &__icon {
                &.mat-icon {
                    width: 22px;
                    height: 22px;
                    font-size: 22px;
                    line-height: 22px;
                }
            }

            &__icon-removed {
                color: mat.get-color-from-palette($accent, 500);
            }

            &__icon-add {
                color: $addIconColor;
            }

            &--not-administrated {
                color: $primary-text;
            }

            &--changed {
                color: $changes-color;
            }
        }

        .mat-column-name {
            position: relative;
            color: mat.get-color-from-palette($foreground, text);
            font-size: 16px;

            & span {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* Number of lines to show */
                word-break: break-all;
                text-overflow: ellipsis;
            }

            &.ui-table-td {
                text-align: left;
            }
        }

        &__group {
            @if $is-dark {
                border-right: 1px solid #403c69;
            } @else {
                border-right: 1px solid #ebebeb;
            }
        }
    }
}
