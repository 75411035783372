@use '@angular/material' as mat;

:host {
    .wlc-header {
        font-size: 32px;
        font-weight: 300;
        margin-bottom: 22px;
        letter-spacing: 0.17px;

        button {
            margin-right: 12px;
        }
    }

    .wlc-container {
        overflow: auto;
        max-height: 85%;
        padding: 0;

        .wlc-section-title {
            padding: 20px;
            font-size: 20px;
            border-bottom: 1px solid #0000001f;
        }

        .wlc-add-type {
            display: flex;
            align-items: center;
            padding: 20px;
            font-size: 16px;
            border-bottom: 1px solid #0000001f;

            mat-icon {
                margin-right: 20px;
            }
        }
    }
}

@mixin workflows-list-container-theme($theme) {
    $background: map-get($theme, background);
    $is-dark: map-get($theme, is-dark);
    $primary: map-get($theme, primary);

    .wlc-container {
        background-color: mat.get-color-from-palette($background, card);

        .wlc-add-type {
            @if $is-dark {
                color: #fff;
            } @else {
                color: mat.get-color-from-palette($primary);
            }
        }
    }
}
