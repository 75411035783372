@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin custom-mat-list-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    .mat-mdc-list-base {
        padding: 0;

        .mat-list-item-disabled {
            background-color: unset;
            color: unset;
            cursor: not-allowed;
        }
    }

    .mdc-list-item__content {
        pointer-events: all;

        & > * {
            letter-spacing: normal;
        }
    }

    .mat-mdc-list-item-unscoped-content {
        display: flex;
        align-items: center;
    }

    .mat-mdc-nav-list .mat-mdc-list-item {
        cursor: pointer;
    }
}
