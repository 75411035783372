@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-patient-management-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .patient-management {
        .patient-management__section {
            .patient-management__section-header {
                @if $is-dark {
                    color: #fff;
                } @else {
                    color: #000;
                }
            }
        }
    }
}
