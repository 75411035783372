@use '@angular/material' as mat;
@import 'variables';
@import '../../../shared/src/lib/add-prescription-sidenav/add-prescription-set.component.theme';
@import 'containers/medication-prescriptions/medication-prescriptions.component.theme';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-medication-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $textColor: if($is-dark, rgba(255, 255, 255, 0.72), rgba(0, 0, 0));

    %theme-color-text {
        @if $is-dark {
            color: rgba(255, 255, 255, 0.32);
        } @else {
            color: rgba(0, 0, 0, 0.32);
        }
    }

    %theme-color-fill {
        @if $is-dark {
            fill: rgba(255, 255, 255, 0.32);
        }
    }

    /* ---------------------------------------------------------------------- */
    /* CHILDREN */
    /* ---------------------------------------------------------------------- */
    @include add-prescription-set-theme($theme);
    @include app-medication-prescriptions-theme($theme);
}
