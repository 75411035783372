@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin custom-sidenav-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    .mat-sidenav,
    .mat-sidenav-inner-container {
        @extend .bg-primary;
    }

    .mat-drawer-backdrop.mat-drawer-shown {
        background-color: rgba(0, 0, 0, 0.32);
    }

    .custom-sidenav {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background-color: transparent;

        &--visible {
            z-index: $mona-sidenav-z;
        }

        &-nav {
            background: transparent;
        }
    }
}
.mat-sidenav .ui-drawer-header-content {
    min-height: 116px;
}
