@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-encounter-history-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    $event-color: mat.get-color-from-palette($accent);
    $report-color: mat.get-color-from-palette($primary);
    $body-examination-color: mat.get-color-from-palette($success);

    .mona-encounter-history {
        background-color: #fff;
        box-shadow: 0 8px 32px rgba(33, 32, 70, 0.05);

        @if $is-dark {
            background-color: mat.get-color-from-palette($primary, 700);
            box-shadow: 0 8px 32px rgba(33, 32, 70, 0.05);
        }

        &__title {
            color: #000;

            @if $is-dark {
                color: rgba(255, 255, 255, 0.72);
            }
        }

        &__menu-type {
            &:after {
                background-color: #000;

                @if $is-dark {
                    background-color: #fff;
                }
            }

            &--report {
                &:after {
                    background-color: $report-color;
                }
            }

            &--event {
                &:after {
                    background-color: $event-color;
                }
            }

            &--body-examination {
                &:after {
                    background-color: $body-examination-color;
                }
            }

            &--all {
                &:after {
                    background-color: #000;
                }
            }
        }

        &__filter {
            color: mat.get-color-from-palette($primary);

            @if $is-dark {
                // Rewriting theme !important
                color: mat.get-color-from-palette($primary, A400) !important;
            }
        }

        &__list {
            &:before,
            &:after {
                background-color: rgba(0, 0, 0, 0.16);

                @if $is-dark {
                    background-color: rgba(255, 255, 255, 0.08);
                }
            }
        }

        &__day {
            background-color: #fff;

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            }

            &:after,
            &:before,
            &-label:after,
            &-label:before {
                background-color: rgba(0, 0, 0, 0.16);

                @if $is-dark {
                    background-color: rgba(255, 255, 255, 0.08);
                }
            }

            &-label {
                color: rgba(0, 0, 0, 0.32);

                @if $is-dark {
                    color: rgba(255, 255, 255, 0.32);
                }
            }
        }

        &__entry {
            &--event {
                .mona-encounter-history__entry-time {
                    color: $event-color;
                }

                &:before {
                    background-color: $event-color;
                }
            }

            &--report {
                .mona-encounter-history__entry-time {
                    color: $report-color;
                }

                .mona-encounter-history__entry-img {
                    border: 1px solid $report-color;
                }

                .mona-encounter-history__entry-indicator {
                    background-color: $report-color;
                }
            }

            &--body-examination {
                .mona-encounter-history__entry-time {
                    color: $body-examination-color;
                }

                .mona-encounter-history__entry-img {
                    border: 1px solid $body-examination-color;
                }

                .mona-encounter-history__entry-indicator {
                    background-color: $body-examination-color;
                }
            }

            &-img {
                background-color: mat.get-color-from-palette($primary, 300);
                color: #fff;
            }

            &-text {
                color: #000;

                @if $is-dark {
                    color: #fff;
                }

                &--changed {
                    color: $changes-color;
                }
            }

            &-practitioner,
            &-report-type {
                color: rgba(0, 0, 0, 0.32);

                @if $is-dark {
                    color: rgba(255, 255, 255, 0.64);
                }
            }

            &-time {
            }
        }

        &__edit {
            color: rgba(0, 0, 0, 0.16);

            @if $is-dark {
                color: rgba(255, 255, 255, 0.64);
            }
        }
    }
}
