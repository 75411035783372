@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-card-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $white: map-get($theme, white);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $text-color: if($is-dark, rgba(255, 255, 255, 0.72), rgba(0, 0, 0));

    .ui-card {
        // 1st: set height for container
        display: flex;
        height: 100%;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;

        &__full {
            height: 100vh;
        }
        &__with-elevation {
            @include mat.elevation(4);
        }

        @if $is-dark {
            background-color: mat.get-color-from-palette($primary, 700);
        }
        // 2nd: use flex 1 for wrapper
        &__wrapper {
            display: flex;
            flex: 1 1 auto;
            overflow: hidden;
        }

        &__action {
            &--close {
                color: $text-color;
                transform: scale(1.3);
                margin-right: -1px;
            }
        }

        .mat-mdc-card {
            transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
            display: block;
            position: relative;
            padding: 16px;
            border-radius: 4px;

            &.ui-card__card {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                align-items: stretch;
                overflow: hidden;
                box-shadow: none !important;
            }

            &-title {
                flex: 0 0 auto;
            }

            &-content {
                flex: 1 1 auto;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 0;
            }

            &-header {
                color: $text-color;
                font-size: 24px;
                margin-bottom: 15px;
                align-items: center;
                padding: 0;

                h2 {
                    order: 2; // INFO: to poperly align header text with close & back buttons
                }
            }

            &-content,
            &-actions {
                // flex-shrink: 0;
                padding-bottom: unset;
            }

            &-header-text {
                // width: 100%;
                margin: 0;
            }
        }
    }

    .external-resources-window {
        @if $is-dark {
            background-color: #a2a0b7;
        } @else {
            background-color: #ede7f6;
        }

        .external-resources-item .icon {
            @if $is-dark {
                background-color: #373267;
            } @else {
                background-color: white;
            }
        }
    }
}

.mat-mdc-card {
    & .mat-mdc-card-header {
        & h2 {
            width: 100%;
            margin: 0;
            font-size: 20px;
            font-weight: 500;
            margin: 17px 0 12px 0;
        }
    }
}

// Remove some default applied styles
.mat-mdc-card .mat-mdc-card-content {
    padding: 0;

    &:first-child {
        padding: 0;
    }

    &:last-child {
        padding: 0;
    }
}
