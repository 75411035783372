@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-lock-screen-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $white: map-get($theme, white);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mona-lock-screen-wrapper {
        .mat-dialog-container {
            border-radius: 0 !important;
            padding: 0 !important;
            box-shadow: none;
        }
    }

    .mona-lock-screen {
        color: $white;
        &__notifications-icon {
            color: mat.get-color-from-palette($accent);
        }
    }

    .mona-maintenance {
        &__icon {
            color: mat.get-color-from-palette($warn);
        }
    }
    .mona-on-screen-keypad__button {
        .mat-mdc-unelevated-button {
            background-color: unset;

            &--submit .mat-mdc-button-persistent-ripple {
                background-color: mat.get-color-from-palette($accent, 500);
            }
        }

        .mdc-button__label {
            color: white !important;
        }

        .mat-mdc-button-persistent-ripple {
            @if $is-dark {
                background-color: #1b1b32;
            } @else {
                background-color: mat.get-color-from-palette($primary, 400);
            }

            .mat-mdc-progress-spinner circle,
            .mat-spinner circle {
                stroke: $white;
            }
        }
    }
    .mona-sign-in-rfid {
        &__icon {
            color: $white;
        }
    }

    .mona-sign-in-pin {
        &__input {
            color: $white;
            background-color: transparent;
            border: none;
            border-bottom: 2px solid rgba($white, 0.5);
            outline: none;

            &::placeholder {
                color: rgba($white, 0.5);
            }

            &-icon {
                color: mat.get-color-from-palette($accent);
            }
        }
    }
}
