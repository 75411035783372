@use '@angular/material' as mat;

@import 'containers/vital-signs-table/vital-signs-table.component.theme';
// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin vital-signs-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .app-vital-signs {
        &__interval {
            color: mat.get-color-from-palette($primary) !important;

            @if $is-dark {
                color: mat.get-color-from-palette($primary, A400) !important;
            }
        }
    }
    /* ---------------------------------------------------------------------- */
    /* CHILDREN */
    /* ---------------------------------------------------------------------- */
    @include app-vital-signs-table-theme($theme);
}
