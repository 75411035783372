@use '@angular/material' as mat;
@use '@angular/material/core/theming/palette' as allpallete;
@import 'variables';

@mixin ui-message-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $success: map-get($theme, success);
    $info: map-get($theme, info);
    $is-dark: map-get($theme, is-dark);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $changes-color: map-get($theme, changes-color);
    $typography: mat.define-typography-config();

    $msgTypes: ('accent', 'warn', 'info', 'error', 'success', 'white', 'changes');

    // Theme for notifications / snackbar
    $messages-theme: (
        default: #fff,
        info: mat.get-color-from-palette(allpallete.$blue-palette, 400),
        success: mat.get-color-from-palette(allpallete.$green-palette, 400),
        warning: mat.get-color-from-palette(allpallete.$yellow-palette, 400),
        error: mat.get-color-from-palette(allpallete.$red-palette, 400),
    );

    .ui-alerts {
        display: block;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 3;

        .mat-paginator {
            background-color: inherit;
            color: inherit;
        }
        .mat-toolbar {
            padding: 0;
            padding-left: $navbar-width + 350px;
            height: $mona-alerts-toolbar-height;
        }
        .mat-paginator-container {
            min-height: $mona-alerts-toolbar-height;
            height: $mona-alerts-toolbar-height;
        }
    }

    .ui-alerts-pager {
        min-width: calc(#{$mona-alerts-toolbar-height} * 2);
    }

    .ui-alerts,
    .ui-message,
    .ui-snackbar {
        &--hidden {
            @extend .d-none;
        }
        &--primary {
            @extend %bg-primary;
        }
        &--accent {
            @extend %bg-accent;
        }
        &--warn {
            @extend %bg-warning;
        }
        &--info {
            @extend %bg-info;
        }
        &--error {
            @extend %bg-error;
        }
        &--success {
            @extend %bg-success;
        }
        &--white {
            @extend %bg-white;
        }
        &--changes {
            @extend %bg-changes;
        }
    }

    .ui-snackbar-container {
        &.mat-mdc-snack-bar-container {
            padding: 0;
            max-width: toRem(500);
        }

        .ui-message {
            border-radius: 4px;
        }
    }

    .ui-message-titles {
        font-size: mat.font-size($typography, 'body-2');
        text-align: center;
    }
}
