@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-medication-prescriptions-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .app-medication-prescriptions__wrapper {
        .ui-table {
            table-layout: auto !important;

            .new-prescription {
                color: white;

                @if $is-dark {
                    background-color: mat.get-color-from-palette($primary, A400);
                } @else {
                    background-color: mat.get-color-from-palette($primary);
                }
            }

            .row-deleted {
                .ui-table-td.mat-mdc-cell {
                    color: gray; // Todo: create var with text-disabled color
                    mat-icon,
                    .changes-asterisk {
                        text-decoration: none;
                    }
                }
            }
            .row-changes {
                td.ui-table-td.mat-mdc-cell:not([class*='ui-table-td--menu-button']) {
                    color: mat.get-color-from-palette($accent);
                }

                .new-prescription {
                    background-color: mat.get-color-from-palette($accent);
                    color: white;
                }
            }

            .row-deleted.row-changes {
                .ui-table-td.mat-mdc-cell {
                    text-decoration: line-through;
                }
            }
        }

        &__last-change-by-role {
            &--doctor {
                color: mat.get-color-from-palette($success);
            }

            &--nurse {
                color: mat.get-color-from-palette($accent);
            }
        }

        &__confirmation {
            color: #ffffff;

            &--disabled {
                opacity: 0.75;
                pointer-events: none;
            }

            &--confirmed {
                background: mat.get-color-from-palette($success);
            }

            &--not-confirmed {
                background: mat.get-color-from-palette($warn);
            }

            .mat-icon {
                margin-right: 18px;
            }

            .mat-mdc-checkbox {
                pointer-events: none;
                margin-right: auto;
            }

            .mdc-checkbox__background {
                border-color: #fff !important;
            }

            .mdc-label {
                font-weight: 500;
                color: #fff;
            }

            .mat-checkbox-frame {
                border-color: #ffffff;
            }

            .mdc-checkbox {
                margin-right: 18px;
            }
        }
    }
}
