@use '@angular/material' as mat;
@import './calendar-slider.variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-calendar-slider-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $active: if($is-dark, mat.get-color-from-palette($primary, A400), mat.get-color-from-palette($primary, 500));

    .ui-calendar-slider {
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        width: $calendar-cell-width * 5;

        li {
            list-style: none;
        }

        &__control {
            color: $active;
        }

        &__list {
            &:before {
                background: linear-gradient(
                    -90deg,
                    mat.get-color-from-palette($background, 700) 0%,
                    mat.get-color-from-palette($background, 700, 0.1) 100%
                );
            }
            &:after {
                background: linear-gradient(
                    -90deg,
                    mat.get-color-from-palette($background, 700) 0%,
                    mat.get-color-from-palette($background, 700, 0.1) 100%
                );
            }
        }

        &__day {
            color: mat.get-color-from-palette($foreground, text);

            &--active {
                color: $active;
            }
            &:hover {
                color: mat.get-color-from-palette($primary, 300);
            }
            &-asterisk {
                color: mat.get-color-from-palette($accent);
            }
            &-badge {
                color: mat.get-color-from-palette($accent);
            }
        }
    }

    .mat-calendar-body-cell-higlighted {
        .mat-calendar-body-cell-content {
            background-color: mat.get-color-from-palette($primary, 200);
        }
    }
}
