@mixin multiple($props, $value) {
    @each $property in $props {
        #{$property}: $value;
    }
}

@mixin invert {
    transform: scaleX(-1);
}

/* Used for scaling icon-like content proportional to system font size. */
@mixin scale-icon-content {
    font-size: 1.5rem;
    height: 1.5rem;
    width: 1.5rem;
}

/* Used for scaling mat-icons inside of mat-buttons. */
@mixin scale-icon-button {
    .mdc-icon-button {
        height: 2.5rem;
        width: 2.5rem;
        line-height: 2.5rem;
        .mat-icon {
            @include scale-icon-content();
            height: unset;
        }
    }
}

// Text Ellipsis
@mixin text-ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Margin utility mixin
@mixin margin-variant($id: 1, $size: $unit-1) {
    .m-#{$id} {
        margin: $size !important;
    }

    .mb-#{$id} {
        margin-bottom: $size !important;
    }

    .ml-#{$id} {
        margin-left: $size !important;
    }

    .mr-#{$id} {
        margin-right: $size !important;
    }

    .mt-#{$id} {
        margin-top: $size !important;
    }

    .mx-#{$id} {
        margin-left: $size !important;
        margin-right: $size !important;
    }

    .my-#{$id} {
        margin-bottom: $size !important;
        margin-top: $size !important;
    }
}

// Padding utility mixin
@mixin padding-variant($id: 1, $size: $unit-1) {
    .p-#{$id} {
        padding: $size !important;
    }

    .pb-#{$id} {
        padding-bottom: $size !important;
    }

    .pl-#{$id} {
        padding-left: $size !important;
    }

    .pr-#{$id} {
        padding-right: $size !important;
    }

    .pt-#{$id} {
        padding-top: $size !important;
    }

    .px-#{$id} {
        padding-left: $size !important;
        padding-right: $size !important;
    }

    .py-#{$id} {
        padding-bottom: $size !important;
        padding-top: $size !important;
    }
}
