@use '../../../../../../../node_modules/@angular/material/index' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-prescription-sets-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $white: map-get($theme, white);
    $black: map-get($theme, black);

    $primary-text: map-get($theme, primary-text);
    $secondary-text: map-get($theme, secondary-text);
    $text-8-opacity: map-get($theme, text-8-opacity);
    $text-12-opacity: map-get($theme, text-12-opacity);
    $text-16-opacity: map-get($theme, text-16-opacity);
    $text-32-opacity: map-get($theme, text-32-opacity);
    $text-72-opacity: map-get($theme, text-72-opacity);

    $icon-color: if($is-dark, rgba($white, 0.72), rgba($black, 0.32));
    $input-color: if($is-dark, $white, rgba($black, 0.82));
    $input-border-color: if($is-dark, $text-8-opacity, $text-12-opacity);
    $placeholder-color: if($is-dark, rgba($white, 0.42), rgba($black, 0.42));

    .mona-prescription-sets {
        padding: 16px;

        &__main-column {
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 2px 2px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            } @else {
                background-color: $white;
            }
        }

        &__info-column {
            background-image: url('^assets/images/settings.svg');
            background-position: 32px bottom;
            background-repeat: no-repeat;
        }

        &__title {
            color: $primary-text;
        }

        &__description {
            color: $text-32-opacity;
        }

        &__back-btn {
            @if $is-dark {
                color: mat.get-color-from-palette($primary, A400);
            } @else {
                color: mat.get-color-from-palette($primary);
            }
        }

        &__content-title {
            color: $text-72-opacity;
        }

        &__empty {
            border: 1px solid $text-16-opacity;

            &-label {
                color: mat.get-color-from-palette($primary, 500);

                @if $is-dark {
                    color: $white;
                }
            }
        }

        &__card {
            @if $is-dark {
                background: mat.get-color-from-palette($primary, 600);
            }

            &-icon {
                color: $text-72-opacity;
            }
        }
    }
}
