@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-shared-medication-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    %theme-color-text {
        @if $is-dark {
            color: rgba(255, 255, 255, 0.32);
        } @else {
            color: rgba(0, 0, 0, 0.32);
        }
    }

    %theme-color-fill {
        @if $is-dark {
            fill: rgba(255, 255, 255, 0.32);
        }
    }

    $padding: 20px;

    .mona-medication {
        &__form {
            display: block;

            &-heading {
                padding-top: 0.5rem;
            }

            &-checkbox {
                padding-bottom: 1rem;
                margin-left: 12px;
                font-weight: 700;
                font-size: 16px;
            }

            &-fieldset {
                &:not(:last-of-type) {
                    padding-top: 0.5rem;
                }
            }
        }

        &-item--actions {
            .mdc-icon-button.mat-primary {
                .mona-medication__icon {
                    @if $is-dark {
                        color: #ffffff;
                    }
                }
            }
        }

        &-list {
            &__search {
                position: relative;

                &-input {
                    width: 100%;
                    padding: 18px 60px 18px 20px;
                    font-size: 16px;
                    background-color: transparent;
                    box-sizing: border-box;
                    border: none;
                    outline: none;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    color: rgba(0, 0, 0, 0.82);

                    &::placeholder {
                        color: rgba(0, 0, 0, 0.42);
                    }

                    @if $is-dark {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.08);
                        color: #fff;

                        &::placeholder {
                            color: rgba(255, 255, 255, 0.42);
                        }
                    }
                }

                &-icon {
                    position: absolute;
                    top: calc(50% - 10px);
                    right: 20px;

                    @if $is-dark {
                        color: rgba(255, 255, 255, 0.72);
                    } @else {
                        color: rgba(0, 0, 0, 0.32);
                    }

                    &.mat-icon {
                        width: 20px;
                        height: 20px;
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    .medication-autocomplete {
        &__input {
            @if $is-dark {
                color: #fff;
            }
        }

        &__icon {
            @extend %theme-color-text;
        }

        &__confirm {
            &-overlay {
                min-height: 200px;
            }
            &-card {
                &-actions {
                    display: flex;
                    justify-content: space-between;
                }
            }
            &-btn {
                width: 38%;
                line-height: unset;
                min-height: 34px;
                text-align: center;
                border-radius: 40px;

                & .mat-mdc-menu-item-text {
                    width: 100%;
                }

                &--primary {
                    width: 60%;

                    &.mat-mdc-menu-item:not([disabled]):hover {
                        background-color: #673ab7;
                    }
                }
            }
        }
    }

    .mat-mdc-chip-listbox {
        margin-left: 6px;
    }

    .mat-mdc-chip.mat-mdc-standard-chip {
        margin: 4px;
        background-color: mat.get-color-from-palette($primary);

        .mat-mdc-chip-action-label {
            color: #fff;
        }

        .mdc-evolution-chip__cell mat-icon {
            color: #fff;
        }
    }
}
