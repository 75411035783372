@use '../../../../../../../node_modules/@angular/material/index' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-prescription-set-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mona-prescription-set {
        position: relative;

        &__action {
            margin-left: 16px;

            &:disabled {
                opacity: 0.4;
            }
        }

        &__action-icon--primary.mat-icon {
            @if $is-dark {
                color: mat.get-color-from-palette($primary, A400);
            }
        }

        &__nav {
            &-actions {
                position: absolute;
                z-index: 2;
                right: 0;
                top: 7px;
            }
        }
    }
}
