@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-notifications-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .mona-notifications {
        height: 100vh;

        @if $is-dark {
            background-color: mat.get-color-from-palette($primary, 700);
        } @else {
            background-color: #fafafa;
        }

        &__logo {
            .mona-logo-fill {
                @if $is-dark {
                    fill: #fff;
                } @else {
                    fill: mat.get-color-from-palette($primary, 500);
                }
            }
        }

        &__message {
            @if $is-dark {
                color: #fff;
            } @else {
                color: rgba(0, 0, 0, 0.72);
            }
        }

        &__close {
            @if $is-dark {
                color: rgba(255, 255, 255, 0.32);
            } @else {
                color: rgba(0, 0, 0, 0.32);
            }
        }

        &__source {
            @if $is-dark {
                color: rgba(255, 255, 255, 0.6);
            } @else {
                color: rgba(0, 0, 0, 0.32);
            }
        }

        .app-notification-card {
            &:not(:last-of-type) {
                margin-bottom: 16px;
            }
        }
    }

    .app-notification-card {
        @if $is-dark {
            background-color: mat.get-color-from-palette($primary, 600);
            border: 2px solid rgba(255, 255, 255, 0.08);

            &--acknowledge {
                &:after {
                    background-color: mat.get-color-from-palette($success);
                }
            }

            &--navigate {
                &:after {
                    background-color: mat.get-color-from-palette($accent);
                }
            }

            &__title {
                color: rgba(255, 255, 255, 0.94);
            }

            &__description {
                color: rgba(255, 255, 255, 0.6);
            }

            &__button {
                color: rgba(255, 255, 255, 0.48) !important;

                &--success {
                    color: mat.get-color-from-palette($success) !important;
                }

                &--action {
                    color: mat.get-color-from-palette($primary, A400) !important;
                }
            }
        } @else {
            background-color: #fff;
            border: 2px solid rgba(218, 218, 218, 0.5);

            &--acknowledge {
                &:after {
                    background-color: mat.get-color-from-palette($success);
                }
            }

            &--navigate {
                &:after {
                    background-color: mat.get-color-from-palette($accent);
                }
            }

            &__title {
                color: rgba(0, 0, 0, 0.72);
            }

            &__description {
                color: rgba(0, 0, 0, 0.6);
            }

            &__button {
                color: rgba(0, 0, 0, 0.72);

                &--success {
                    color: mat.get-color-from-palette($success);
                }

                &--action {
                    color: mat.get-color-from-palette($primary);
                }
            }
        }
    }
}
