@use '@angular/material' as mat;

:host {
    display: block;
    height: calc(100vh - 8.75rem);

    .wd {
        &-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }

        &-content {
            flex-grow: 1;
            height: 75%;
        }

        &-header,
        &-content,
        &-footer {
            flex-shrink: 0;
            padding-bottom: unset;
        }

        &-title {
            font-size: 16px;
            justify-content: space-between;
            display: flex;
            align-items: center;

            .wd-info {
                opacity: 32%;
            }

            .wd-actions-container {
                display: flex;
                align-items: flex-start;

                mat-icon {
                    font-size: 19px;
                    height: 19px;
                    width: 19px;
                    margin-right: 15px;
                }
            }
        }

        &-footer {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            height: 48px;

            .footer-notation {
                max-width: 50%;
                opacity: 32%;
            }

            .footer-actions {
                display: flex;
                align-items: center;

                button {
                    margin-left: 40px;
                }
            }
        }

        &-back {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 500;
            opacity: 72%;
            display: flex;
            align-items: center;

            mat-icon {
                margin-right: 10px;
            }
        }
    }
}

@mixin workflow-details-theme($theme) {
    $primary: map-get($theme, primary);
    $is-dark: map-get($theme, is-dark);

    .wd-action {
        color: mat.get-color-from-palette($primary, 500) !important;

        mat-icon {
            font-size: 24px;
            height: 24px;
            width: 24px;
        }
    }

    .fa-cancel {
        color: mat.get-color-from-palette($primary, 500) !important;
    }

    .suggestion-label {
        color: #979797;

        @if $is-dark {
            color: rgba(255, 255, 255, 0.72);
        }
    }
}
