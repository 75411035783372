@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-balance-chart-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .app-balance-chart {
        $legendColors: ('#E91E63', '#EF6C00', '#4527A0');

        @each $chartColor in $legendColors {
            .chart-color--#{str-slice($chartColor, 2)} {
                .mat-pseudo-checkbox {
                    color: unquote($chartColor) !important;

                    &-checked {
                        background: unquote($chartColor) !important;
                    }
                }
            }
        }
    }
}
