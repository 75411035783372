@use '@angular/material' as mat;

@mixin custom-icon-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    // blend the colors together based on the base color and the theme background.
    $white-30pct-opacity-on-dark: #686868;
    $black-26pct-opacity-on-light: #b0b0b0;
    $disabled-color: if($is-dark, $white-30pct-opacity-on-dark, $black-26pct-opacity-on-light);

    .mat-icon {
        &--bicolor {
            svg > *,
            svg > * > * {
                @if $is-dark {
                    color: #fff !important;
                    fill: #fff;
                }
            }
            svg rect {
                @if $is-dark {
                    fill: transparent;
                    stroke: #fff;
                }
            }
        }

        &--overview {
            svg > *,
            svg > * > * {
                fill: #673ab7;
            }

            &.activeIcon {
                svg > *,
                svg > * > * {
                    fill: #fff;
                }
            }
        }

        .mat-mdc-menu-item & {
            color: inherit !important;
        }
    }

    .mat-mdc-menu-item[disabled] {
        .mat-icon {
            color: inherit !important;
        }
    }
}
