@use '@angular/material' as mat;

@import 'components/balance-chart/balance-chart.component.theme';
@import 'components/balance-table/balance-table.component.theme';
@import 'components/inputs-table/inputs-table.component.theme';
@import 'components/outputs-table/outputs-table.component.theme';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin balance-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $changes-color: map-get($theme, changes-color);

    $activeColor: if($is-dark, mat.get-color-from-palette($primary, A400), mat.get-color-from-palette($primary, 500));

    .app-balance {
        &__interval {
            color: mat.get-color-from-palette($primary) !important;

            @if $is-dark {
                // Rewriting theme !important
                color: mat.get-color-from-palette($primary, A400) !important;
            }
        }

        &__nav {
            &.mat-mdc-tab-header {
                border-bottom: none;
            }

            a {
                opacity: 1;
                @if $is-dark {
                    color: rgba(255, 255, 255, 0.72);
                } @else {
                    color: #000;
                }

                &.mdc-tab--active {
                    color: $activeColor;
                }
            }
        }
    }

    /* ---------------------------------------------------------------------- */
    /* CHILDREN */
    /* ---------------------------------------------------------------------- */
    @include app-balance-chart-theme($theme);
    @include app-balance-table-theme($theme);
    @include app-inputs-table-theme($theme);
    @include app-outputs-table-theme($theme);
}
