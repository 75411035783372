@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-loading-overlay-theme($theme) {
    $primary: map-get($theme, primary);
    $is-dark: map-get($theme, is-dark);

    $overlay-background: rgba(250, 250, 250, 0.8);
    $overlay-background-dark: mat.get-color-from-palette($primary, 500, 0.82);
    $overlay-hidden-transition: opacity 200ms ease;

    .loading-overlay {
        @if $is-dark {
            background-color: $overlay-background-dark;
        } @else {
            background-color: $overlay-background;
        }

        align-content: center;
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        will-change: opacity;
        z-index: $loading-overlay-z;
        overflow: hidden;

        &--hidden {
            opacity: 0;
            transition: $overlay-hidden-transition;
        }

        .mat-spinner circle {
            @if $is-dark {
                stroke: mat.get-color-from-palette($primary, 50) !important;
            }
        }
    }
}
