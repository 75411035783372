@use '@angular/material' as mat;
@use 'sass:string';
@use 'sass:map';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-chart-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .ui-chart {
        &-container {
            height: inherit;
            max-height: 300px;
            padding: 0;
            display: grid;
            grid:
                'legend canvas' 1fr
                / minmax(0, 340px) 1fr;
            justify-items: stretch;
        }

        &-legend {
            grid-area: legend;
        }

        &-canvas {
            grid-area: canvas;
        }

        &-legend {
            &__wrapper {
                background-color: mat.get-color-from-palette($background);
            }

            .scrollbar-visible::-webkit-scrollbar {
                &:vertical {
                    width: 2px;
                }

                &:horizontal {
                    height: 4px;
                }
            }
        }

        &-empty-block {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            backdrop-filter: blur(5px);
            font-size: 22px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;

            @if $is-dark {
                color: rgba(255, 255, 255, 0.56);
                background-color: rgba(0, 0, 0, 0.1);
            } @else {
                color: rgba(0, 0, 0, 0.56);
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
}
