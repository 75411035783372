@use '@angular/material' as mat;

:host {
    .wt-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: -20px;
        max-height: calc(100vh - 64px - 57px);
        overflow: auto;

        .wt-tile {
            height: 160px;
            width: 300px;
            border-radius: 8px;
            margin: 20px;
            overflow: hidden;
            filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12))
                drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));

            &:focus {
                outline: none;
            }

            .wt-preview {
                display: flex;
                justify-content: center;
                background-color: #ede7f6;

                img {
                    margin-top: 15px;
                }
            }

            .wt-info {
                display: flex;
                padding: 14px 20px;

                .wt-name {
                    text-transform: uppercase;
                    font-size: 16px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }
}

@mixin workflow-types-list-theme($theme) {
    $background: map-get($theme, background);

    .wt-tile {
        background-color: mat.get-color-from-palette($background, card);
    }
}
