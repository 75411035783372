@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-server-url-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mona-server-url-dialog {
        .mat-dialog-container {
            width: 820px;
            padding: 40px 40px 24px;

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            }
        }

        &__title {
            color: #000;

            @if $is-dark {
                color: #fff;
            }
        }

        &__description {
            @if $is-dark {
                color: rgba(255, 255, 255, 0.72);
            } @else {
                color: rgba(0, 0, 0, 0.72);
            }
        }

        &__verify {
            &--failed {
                .mona-server-url-dialog__verify-icon {
                    color: mat.get-color-from-palette($warn);
                }
            }

            &--success {
                .mona-server-url-dialog__verify-icon {
                    color: mat.get-color-from-palette($success);
                }
            }
        }
    }
}
