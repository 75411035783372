@use '@angular/material' as mat;

@mixin app-personal-data-form-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .app-personal-data-form {
        &__search-button {
            @if $is-dark {
                color: mat.get-color-from-palette($primary, A400);
            } @else {
                color: mat.get-color-from-palette($primary);
            }

            .mat-mdc-progress-spinner circle,
            .mat-spinner circle {
                @if $is-dark {
                    stroke: mat.get-color-from-palette($primary, A400);
                } @else {
                    stroke: mat.get-color-from-palette($primary);
                }
            }
        }
    }
}

@mixin app-allergy-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .app-allergy-dialog {
        .mat-dialog-container {
            width: 820px;
            padding: 40px 40px 24px;

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            }
        }

        &__title {
            color: #000;

            @if $is-dark {
                color: #fff;
            }
        }
    }
}

@mixin app-anamnesis-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .app-anamnesis-dialog {
        .mat-dialog-container {
            width: 820px;
            padding: 40px 40px 24px;

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            }
        }

        &__title {
            color: #000;

            @if $is-dark {
                color: #fff;
            }
        }
    }
}

@mixin app-diagnosis-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .app-diagnosis-dialog {
        .mat-dialog-container {
            width: 820px;
            padding: 40px 40px 24px;

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            }
        }

        &__title {
            color: #000;

            @if $is-dark {
                color: #fff;
            }
        }
    }
}

@mixin app-physical-examination-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .app-physical-examination-dialog {
        .mat-dialog-container {
            width: 820px;
            padding: 40px 40px 24px;

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            }
        }

        &__title {
            color: #000;

            @if $is-dark {
                color: #fff;
            }
        }
    }
}

@mixin app-pre-medication-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .app-pre-medication-dialog {
        .mat-dialog-container {
            width: 820px;
            padding: 40px 40px 24px;

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            }
        }

        &__title {
            color: #000;

            @if $is-dark {
                color: #fff;
            }
        }
    }
}

@mixin mona-history-entry-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mona-history-entry-dialog {
        .mat-dialog-container {
            width: 820px;
            padding: 40px 40px 24px;

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            }
        }

        &__title {
            color: #000;

            @if $is-dark {
                color: #fff;
            }
        }
    }
}
