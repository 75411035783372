@use '@angular/material' as mat;
@import 'variables';

@mixin ui-empty-state-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $changes-color: map-get($theme, changes-color);

    .ui-empty-state {
        position: sticky;
        display: block;
        width: 600px;
        top: 0;
        margin-top: 2rem;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 3;

        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &__img {
            height: 200px;

            @media screen and (max-height: 980px) {
                height: 150px;
            }
        }

        &__title {
            // TODO: find a way to get with mat.get-color-from-palette($primary, 600);
            @if $is-dark {
                color: #ebebeb;
            } @else {
                color: mat.get-color-from-palette($primary);
            }
            font-size: 1.5rem;
            font-weight: 300;
            line-height: 1;
            margin-bottom: 0.5rem;
            text-align: center;
        }

        &__subtitle {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            max-width: 30rem;
            text-align: center;
            font-size: 1.1rem;
            line-height: 1.2em;
            color: mat.get-color-from-palette($foreground, text);
        }

        &__cta {
            min-width: 10rem;
        }
    }
}
