@use '@angular/material' as mat;
@import 'variables';

// @import 'components/set-medication/set-medication.component.theme';
// @import 'components/set-procedure/set-procedure.component.theme';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-settings-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $primary-text: map-get($theme, primary-text);
    $secondary-text: map-get($theme, secondary-text);
    $white: map-get($theme, white);
    $changes-color: map-get($theme, changes-color);

    .mona-settings {
        &__title {
            color: $primary-text;
        }

        &__description {
            font-size: 16px;
            white-space: pre-wrap;
            @if $is-dark {
                color: rgba(255, 255, 255, 0.32);
            } @else {
                color: rgba(0, 0, 0, 0.32);
            }
        }

        &__header {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;

            &__back-btn {
                position: absolute;
                top: -5px;
                left: -48px;
            }

            &-title {
                font-size: 32px;
                font-weight: 300;
                letter-spacing: 0.17px;
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
                align-content: center;
            }

            &-subtitle {
                font-size: 16px;
            }

            &-buttons {
                display: flex;
            }
        }

        &__save {
            position: absolute;
            top: 0;
            left: -86px;
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;

            &--disabled {
                pointer-events: none;
                opacity: 0.7;
            }

            &.mat-mini-fab {
                background-color: $changes-color;
                color: #fff;
            }

            // rewriting material default shadow
            &.mat-mini-fab:not([class*='mat-elevation-z']) {
                box-shadow: 0px 6px 32px rgba(255, 143, 0, 0.25);
            }

            &-spinner {
                &.mat-mdc-progress-spinner circle,
                .mat-spinner circle {
                    stroke: #fff;
                }
            }
        }

        &__nav {
            height: 100%;

            .mat-tab-body-wrapper {
                height: 100%;
            }

            .mat-mdc-tab-header {
                width: calc(100% - 20px);
                // margin: 0 auto;

                .mat-mdc-tab {
                    opacity: 1;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: 500;

                    &.mdc-tab--active .mdc-tab__text-label {
                        @if $is-dark {
                            color: mat.get-color-from-palette($primary, A400);
                        } @else {
                            color: mat.get-color-from-palette($primary, 500);
                        }
                    }

                    .mdc-tab__text-label {
                        @if $is-dark {
                            color: rgba(255, 255, 255, 0.72);
                        } @else {
                            color: #000;
                        }
                    }
                }
            }
        }

        &__empty {
            width: 666px;
            height: 444px;
            text-align: center;
            border-radius: 24px;
            padding: 42px;
            background-image: url('^assets/images/empty.svg');
            background-position: center bottom;
            background-repeat: no-repeat;

            // Improves visual effect
            margin-top: -40px;

            &-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
            }

            &-label {
                font-size: 36px;
                font-weight: 300;
                text-align: center;
                line-height: normal;
            }
        }

        &__table {
            width: calc(100% - 1rem);
            height: calc(100% - 4px);
            margin: 0 auto;

            .mat-mdc-cell,
            .mat-mdc-header-cell,
            .mat-mdc-footer-cell {
                box-sizing: border-box;
                padding: 0 18px;
            }

            .mat-mdc-header-cell.mat-column-filter,
            .mat-column-name {
                width: 280px;
                max-width: 280px;
                min-width: 280px;
                justify-content: space-between;
                padding-right: 8px;

                &.mat-mdc-footer-cell {
                    justify-content: flex-start;
                }
            }
        }
    }

    .mona-settings-section {
        &__title {
            @if $is-dark {
                color: rgba(255, 255, 255, 0.72);
            } @else {
                color: rgba(0, 0, 0, 0.72);
            }
        }

        &__list-item {
            outline: none;

            &-value {
                display: flex;

                @if $is-dark {
                    color: rgba(255, 255, 255, 0.32);
                } @else {
                    color: rgba(0, 0, 0, 0.32);
                }

                &--colored {
                    @if $is-dark {
                        color: #fff;
                    } @else {
                        color: mat.get-color-from-palette($primary);
                    }
                }
            }

            &--colored {
                @if $is-dark {
                    color: #fff;
                } @else {
                    color: mat.get-color-from-palette($primary);
                }
            }
        }

        &__icon {
            @if $is-dark {
                background-color: rgba(255, 255, 255, 0.08);
                color: rgba(255, 255, 255, 0.72);
            } @else {
                background-color: rgba(0, 0, 0, 0.08);
                color: rgba(0, 0, 0, 0.72);
            }
        }

        &__select {
            .mat-mdc-select-value {
                max-width: 100% !important;
                width: auto !important;
            }

            @if $is-dark {
                .mat-mdc-select-value,
                .mat-mdc-select-arrow {
                    color: #fff;
                }
            } @else {
                .mat-mdc-select-value,
                .mat-mdc-select-arrow {
                    color: mat.get-color-from-palette($primary);
                }
            }
        }

        &__slide {
            @if $is-dark {
                .mat-mdc-slide-toggle-content {
                    color: #fff;
                }

                &.mat-mdc-slide-toggle {
                    --mdc-switch-selected-handle-color: rgba(255, 255, 255, 0.32);
                    --mdc-switch-selected-hover-handle-color: rgba(255, 255, 255, 0.32);
                    --mdc-switch-selected-focus-handle-color: rgba(255, 255, 255, 0.32);
                    --mdc-switch-selected-pressed-handle-color: rgba(255, 255, 255, 0.32);
                    --mdc-switch-unselected-hover-handle-color: rgba(255, 255, 255, 0.32);
                    --mdc-switch-unselected-focus-track-color: #ffffff8a;
                    --mdc-switch-unselected-hover-track-color: #ffffff8a;
                    --mdc-switch-unselected-pressed-handle-color: #ffffff8a;
                    --mdc-switch-unselected-pressed-track-color: #ffffff8a;
                    --mdc-switch-unselected-track-color: #ffffff8a;
                    --mdc-switch-selected-track-color: #ffffff8a;
                    --mdc-switch-selected-focus-track-color: #ffffff8a;
                    --mdc-switch-selected-hover-track-color: #ffffff8a;
                    --mdc-switch-selected-pressed-track-color: #ffffff8a;
                    --mdc-switch-selected-track-color: #ffffff8a;

                    .mdc-switch__shadow {
                        background-color: rgba(255, 255, 255, 0.32);
                    }

                    .mdc-switch__icons > svg {
                        display: none;
                    }

                    label {
                        color: #fff;
                        font-weight: 500;
                    }
                }
            } @else {
                .mat-mdc-slide-toggle-content {
                    color: mat.get-color-from-palette($primary);
                }

                &.mat-mdc-slide-toggle {
                    --mdc-switch-selected-focus-track-color: #673ab752;
                    --mdc-switch-selected-hover-track-color: #673ab752;
                    --mdc-switch-selected-pressed-track-color: #673ab752;
                    --mdc-switch-unselected-focus-track-color: rgba(104, 58, 183, 0.379);
                    --mdc-switch-unselected-hover-track-color: rgba(104, 58, 183, 0.379);
                    --mdc-switch-unselected-pressed-handle-color: rgba(104, 58, 183, 0.379);
                    --mdc-switch-unselected-pressed-track-color: rgba(104, 58, 183, 0.379);
                    --mdc-switch-unselected-track-color: rgba(104, 58, 183, 0.379);
                    --mdc-switch-selected-track-color: rgba(104, 58, 183, 0.379);

                    .mdc-switch__shadow {
                        background-color: lighten(mat.get-color-from-palette($primary), 20%);
                    }

                    .mdc-switch__icons > svg {
                        display: none;
                    }

                    label {
                        color: mat.get-color-from-palette($primary, 500);
                        font-weight: 500;
                    }
                }
            }
        }

        &__menu-trigger {
            @if $is-dark {
                color: #fff;

                .mat-icon {
                    color: rgba(255, 255, 255, 0.7);
                }
            } @else {
                color: mat.get-color-from-palette($primary);
            }
        }

        &__option--highlighted {
            background-color: mat.get-color-from-palette($primary, 0.12);
        }
    }

    /* workaround to be able to see mat-card default border */
    .mat-tab-body-content {
        padding: 2px 1px;
    }

    /* ---------------------------------------------------------------------- */
    /* CHILDREN */
    /* ---------------------------------------------------------------------- */
    // @include mona-set-medication-theme($theme);
    // @include mona-set-procedure-theme($theme);
}
