@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin custom-input-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);
    $required-label-color: mat.get-color-from-palette($accent, text);
    $changes-color: map-get($theme, changes-color);
    $letter-spacing: normal;

    $white: map-get($theme, white);
    $black: map-get($theme, black);

    $primary-text: map-get($theme, primary-text);
    $secondary-text: map-get($theme, secondary-text);
    $text-8-opacity: map-get($theme, text-8-opacity);
    $text-12-opacity: map-get($theme, text-12-opacity);
    $text-16-opacity: map-get($theme, text-16-opacity);
    $text-32-opacity: map-get($theme, text-32-opacity);
    $text-64-opacity: map-get($theme, text-64-opacity); // rgba(black, 0.64);
    $text-72-opacity: map-get($theme, text-72-opacity);

    $icon-color: if($is-dark, rgba($white, 0.72), rgba($black, 0.32));
    $input-color: if($is-dark, $white, rgba($black, 0.82));
    $input-border-color: if($is-dark, $text-8-opacity, $text-12-opacity);
    $placeholder-color: if($is-dark, rgba($white, 0.42), rgba($black, 0.42));

    fieldset {
        border: 0;
        padding: 0.01em 0 0 0;
        margin: 0;
        min-width: 0;

        &:disabled {
            pointer-events: none;

            &.mat-mdc-form-field-disabled {
                .mat-mdc-form-field-underline::before {
                    background-color: transparent;
                    bottom: 1px;

                    @if $is-dark {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.16);
                    } @else {
                        border-bottom: 1px solid rgb(185, 185, 185);
                    }
                }
            }

            .mat-mdc-form-field-required-marker {
                opacity: 0;
            }

            .mat-mdc-form-field-flex {
                @if $is-dark {
                    background-color: rgba(255, 255, 255, 0.08);
                } @else {
                    background-color: $mona-background-color-light;
                }

                .mat-input-element,
                .mat-mdc-select-value {
                    color: $text-72-opacity;
                }
            }
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input {
        font: unset; // reset browser;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    @if $is-dark {
        .mat-input-element {
            caret-color: $white;
        }
    }

    .app-input-field {
        font-size: 16px;
        flex: 1 1 auto;
        margin-right: 16px;
        // letter-spacing: normal !important;

        &--fullwidth {
            width: 100%;
            margin-right: 0;
        }

        &--value {
            width: calc(50% - 16px);
        }

        &--unit {
            width: 50%;
            margin-right: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        .mdc-icon-button {
            opacity: 0.56;
        }

        .mat-mdc-input-element {
            letter-spacing: normal;
        }

        @if $is-dark {
            &.mat-mdc-form-field.mat-warn .mat-input-element,
            &.mat-mdc-form-field-invalid .mat-input-element {
                caret-color: $dark-theme-error;
            }

            .mat-error,
            &.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label,
            &.mat-mdc-form-field.mat-mdc-form-field-invalid
                .mat-mdc-form-field-label
                .mat-mdc-form-field-required-marker {
                color: $dark-theme-error;
            }

            &.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-ripple,
            &.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-ripple.mat-accent {
                background-color: $dark-theme-error;
            }
        }

        .mat-mdc-form-field-error {
            line-height: 10px;
            letter-spacing: normal;
        }

        &__suffix {
            color: $text-32-opacity;

            &--dynamic {
                visibility: hidden;
            }
        }

        .mat-mdc-form-field-hint-wrapper,
        .mat-mdc-form-field-error-wrapper {
            margin-top: -2px;
            padding: 0 12px;
        }

        .mat-mdc-form-field-hint {
            color: $text-32-opacity;
        }

        &:not(.mat-mdc-form-field-invalid, .mat-mdc-form-field-empty) .mat-mdc-form-field-label {
            color: $text-72-opacity;
        }

        &.mat-focused:not(.mat-mdc-form-field-invalid),
        &.app-focused-input:not(.mat-mdc-form-field-invalid) {
            .mat-mdc-form-field-ripple {
                @if $is-dark {
                    background-color: mat.get-color-from-palette($primary, A400);
                } @else {
                    background-color: mat.get-color-from-palette($primary);
                }
            }
        }

        .mat-datepicker-toggle,
        mat-icon {
            color: $text-32-opacity;
        }

        &.mat-form-field-appearance-fill {
            &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float:not(.mat-mdc-form-field-invalid) {
                .mat-mdc-form-field-label {
                    color: $text-32-opacity;
                }

                &.mat-focused,
                &.app-focused-input {
                    .mat-mdc-form-field-label {
                        @if $is-dark {
                            color: mat.get-color-from-palette($primary, A400);
                        } @else {
                            color: mat.get-color-from-palette($primary);
                        }
                    }
                }
            }

            &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float {
                .app-input-field__suffix--dynamic {
                    visibility: visible;
                }
            }

            &:not(.mat-mdc-form-field-invalid) .mat-mdc-form-field-underline::before {
                @if $is-dark {
                    background-color: rgba(255, 255, 255, 0.38);
                } @else {
                    background-color: rgba(0, 0, 0, 0.38);
                }
            }

            &.mat-mdc-form-field-disabled {
                .mat-mdc-form-field-underline::before {
                    background-color: transparent;
                    bottom: 1px;

                    @if $is-dark {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.16);
                    } @else {
                        border-bottom: 1px solid rgb(185, 185, 185);
                    }
                }
            }

            .mat-mdc-form-field-flex {
                .mat-input-element,
                .mat-mdc-select-value {
                    color: $text-72-opacity;
                }
            }
        }

        .mat-mdc-text-field-wrapper {
            padding-top: 4px;
            padding-bottom: 2px;

            @if $is-dark {
                background-color: rgba(255, 255, 255, 0.08);
            } @else {
                background-color: $mona-background-color-light;
            }
        }

        .mdc-text-field--filled.mdc-text-field--disabled {
            @if $is-dark {
                border-bottom: 1px solid rgba(255, 255, 255, 0.42);
            } @else {
                border-bottom: 1px solid rgba(0, 0, 0, 0.42);
            }
            & .mdc-text-field__input {
                color: inherit;
            }
        }

        .mdc-floating-label.mat-mdc-floating-label mat-label {
            letter-spacing: normal;
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
        .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
            .mdc-floating-label--float-above {
            letter-spacing: normal;
            @if $is-dark {
                color: #9575cd;
            }
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
            @if $is-dark {
                border-bottom-color: #9575cd;
            }
        }

        .mat-mdc-form-field-icon-suffix {
            right: 12px;
        }

        .mat-mdc-form-field-textarea-control {
            line-height: 1.4;
        }

        .mat-mdc-text-field-wrapper {
            max-height: 59px;
        }

        &.mat-mdc-form-field-type-medication-autocomplete {
            .mat-mdc-floating-label {
                top: 14px;
                font-size: 12px;
            }

            .mdc-floating-label--float-above {
                transform: none;
                top: 6px;
            }
        }
    }

    .app-input-select-panel {
        @if $is-dark {
            background-color: mat.get-color-from-palette($primary, 700);
        }

        .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple) {
            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, A100);
            } @else {
                background-color: mat.get-color-from-palette($primary, 50, 0.8);
            }
        }

        .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-disabled) {
            @if $is-dark {
                color: mat.get-color-from-palette($primary, A400);
            }
        }

        .mat-mdc-option.mat-mdc-option-active,
        .mat-mdc-option:hover {
            @if $is-dark {
                background-color: rgba(255, 255, 255, 0.04);
            } @else {
                background-color: mat.get-color-from-palette($primary, 50, 0.2);
            }
        }

        + .mat-mdc-form-field-label-wrapper > .mat-mdc-form-field-label.mat-mdc-form-field-empty {
            color: $text-32-opacity;
        }

        .mat-mdc-text-field-wrapper {
            max-height: 59px;
        }

        .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
            padding-top: 0px;
        }

        .mat-mdc-select-value {
            padding-bottom: 4px;
        }
    }

    .app-input-calendar-panel {
        @if $is-dark {
            background-color: mat.get-color-from-palette($primary, 700);
        }
    }

    // Styles for custom focus logic
    .mat-mdc-form-field.app-focused-input {
        .mat-mdc-form-field-ripple {
            opacity: 1;
            transform: scaleX(1);
        }
    }

    .app-focused-input .mat-mdc-form-field-required-marker {
        color: $required-label-color;
    }

    // Styles for stage changed input highlighting
    .app-input-field.app-changed-input {
        .mat-mdc-select-value {
            color: $changes-color;
        }

        &.mat-focused:not(.mat-mdc-form-field-invalid),
        &.app-focused-input:not(.mat-mdc-form-field-invalid) {
            .mat-mdc-form-field-ripple {
                background-color: $changes-color;
            }
        }

        .mat-mdc-form-field-ripple {
            background-color: $changes-color;
        }

        &.mat-mdc-form-field-appearance-fill {
            &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float:not(.mat-mdc-form-field-invalid) {
                .mat-mdc-form-field-label {
                    color: rgba($changes-color, 0.64);
                }

                &.mat-focused,
                &.app-focused-input {
                    .mat-mdc-form-field-label {
                        color: $changes-color;
                    }
                }
            }

            &:not(.mat-mdc-form-field-invalid) .mat-mdc-form-field-underline::before {
                background-color: rgba($changes-color, 0.38);
            }

            .mat-mdc-form-field-flex {
                .mat-input-element {
                    color: $changes-color;
                }
            }
        }
    }

    .mat-form-field-appearance-outline .mat-mdc-floating-label {
        display: none;
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
    }

    .mdc-line-ripple--active::before {
        border-bottom-width: 2px;
    }

    .mat-mdc-select-arrow-wrapper {
        padding-left: 4px;
    }

    .search-input-field {
        .mat-mdc-text-field-wrapper {
            padding-top: 4px;
            padding-bottom: 2px;

            @if $is-dark {
                background-color: rgba(255, 255, 255, 0.08);
            } @else {
                background-color: mat.get-color-from-palette($primary, 50, 0.8);
            }
        }
    }
}
