@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin custom-mat-select-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    .mat-mdc-select-panel {
        min-width: calc(100% + 64px);

        .mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
            background-color: mat.get-color-from-palette($primary);
        }

        .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
            @if $is-dark {
                color: #fff;
            } @else {
                color: rgba(#000, 0.82);
            }
        }

        .mat-pseudo-checkbox-minimal {
            display: none;
        }

        & .mat-mdc-option {
            letter-spacing: normal;
        }
    }

    .mat-mdc-pseudo-checkbox::after {
        color: white;
    }

    .mdc-list-item__primary-text {
        text-overflow: ellipsis;
        text-wrap: nowrap !important;
        overflow: hidden;
    }
}
