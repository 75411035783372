@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin custom-mat-radio-button-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__outer-circle {
        @if $is-dark {
        } @else {
            border-color: mat.get-color-from-palette($primary) !important;
        }
    }

    .mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle,
    .mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mat-radio-persistent-ripple,
    .mat-mdc-radio-button.mat-accent:active .mat-radio-persistent-ripple,
    .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__background::before {
        @if $is-dark {
        } @else {
            border-color: mat.get-color-from-palette($primary) !important;
            background-color: mat.get-color-from-palette($primary) !important;
        }
    }

    .mat-mdc-radio-button {
        max-width: 100% !important;

        & .mdc-form-field {
            height: 20px;
        }
    }
}
