@use '@angular/material' as mat;

:host {
    display: flex;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 0px 2px #00000024, 0px 2px 2px #0000001f, 0px 1px 3px #00000033;

    .question-container {
        padding: 40px;
        width: 100%;

        .question-info {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .general-info {
                width: 49%;
            }

            .type-info {
                width: 250px;
            }
        }

        .question-body {
            margin-top: 20px;
        }

        .types-dialog-btn {
            font-size: 16px;
            display: flex;
            align-items: center;
            margin-top: 15px;

            mat-icon {
                margin-left: 8px;
                width: 20px;
                height: 20px;
            }
        }
    }

    .question-preview-container {
        padding: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;

        .question-preview-type {
            display: flex;
            align-items: center;
            opacity: 50%;

            mat-icon {
                margin: 0 10px;
            }
        }
    }

    .question-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 40px;

        button {
            text-transform: none;
            font-weight: 400;

            mat-icon {
                margin-left: 10px;
                font-size: 20px;
                margin-right: 0;
            }
        }
    }
}

@mixin workflow-question-theme($theme) {
    $background: map-get($theme, background);

    $is-dark: map-get($theme, is-dark);
    $primary: map-get($theme, primary);

    .types-dialog-btn {
        color: mat.get-color-from-palette($primary);

        @if $is-dark {
            color: #fff;
        }
    }

    .question-container,
    .question-preview-container {
        background-color: mat.get-color-from-palette($background, card);
    }
}
