@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ui-drawer-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $white: map-get($theme, white);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $drawer-background-color: mat.get-color-from-palette($background, dialog);
    $drawer-container-background-color: mat.get-color-from-palette($background, background);
    $drawer-push-background-color: mat.get-color-from-palette($background, dialog);

    .ui-drawer,
    .ui-drawer-layout-sidenav,
    .ui-drawer-header,
    .ui-drawer-header-content {
        background-color: mat.get-color-from-palette($background, card);

        @if $is-dark {
            color: rgba(255, 255, 255, 0.72);
        } @else {
            color: #000;
        }
    }

    .ui-dropdown-toolbar-content {
        background-color: unset;
    }

    .ui-drawer-layout-content {
        .ui-dropdown-toolbar {
            padding-top: 20px;
            .ui-dropdown-toolbar-content {
                padding-right: 64px;
            }
        }
    }

    .ui-drawer-layout-container {
        flex: 1 1 100%;
        display: flex;
    }

    .ui-drawer-body-close-btn {
        background-color: mat.get-color-from-palette($background, card);
    }

    .ui-drawer-nav-item {
        &-active {
            .ui-info-list-item-content,
            .mat-mdc-list-base .mdc-list-item__content.ui-info-list-item-content {
                .ui-info-list-item-title-wrapper,
                .ui-info-list-item-icon-wrapper {
                    color: mat.get-color-from-palette($primary, 500);
                }
            }
            &-highlight {
                @if $is-dark {
                    background-color: rgba(255, 255, 255, 0.5);
                } @else {
                    background-color: mat.get-color-from-palette($primary, 50);
                }
            }
        }
    }

    .ui-info-list-item-icon-wrapper {
        @if $is-dark {
            color: #fff;
        } @else {
            color: #000;
        }
    }
}
