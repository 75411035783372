@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin custom-colors-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $changes-color: map-get($theme, changes-color);

    ////////////////////////// Foregrounds

    .text-foreground {
        color: mat.get-color-from-palette($foreground);
    }
    .text-primary {
        color: mat.get-color-from-palette($primary);
    }

    .text-accent {
        color: mat.get-color-from-palette($accent);
    }

    .text-warn {
        color: mat.get-color-from-palette($warn);
    }

    .text-success {
        color: mat.get-color-from-palette($success);
    }

    .text-changes {
        color: $changes-color !important;
    }

    .text-muted {
        opacity: 0.54;
    }

    ////////////////////// Backgrounds

    %bg-card,
    .bg-card {
        background-color: mat.get-color-from-palette($background, card) !important;
    }

    %bg-primary,
    .bg-primary {
        background-color: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, default-contrast);
    }

    %bg-accent,
    .bg-accent {
        background-color: mat.get-color-from-palette($accent);
        color: mat.get-color-from-palette($accent, default-contrast);
    }

    %bg-warn,
    .bg-warn {
        background-color: mat.get-color-from-palette($warn);
        color: mat.get-color-from-palette($warn, default-contrast);
    }

    %bg-info,
    .bg-info {
        background-color: #2a8eea;
        color: #fff;
    }

    %bg-warning,
    .bg-warning {
        background-color: $pdms-background-color-warning;
        color: #fff;
    }

    %bg-error,
    .bg-error {
        background-color: $mona-error;
        color: #fff;
    }

    %bg-success,
    .bg-success {
        background-color: mat.get-color-from-palette($success);
        color: mat.get-color-from-palette($success, default-contrast);
    }

    %bg-white,
    .bg-white {
        background-color: #fff;
        color: #000;
    }

    %bg-changes,
    .bg-changes {
        background-color: $changes-color;
    }
}
