@use '@angular/material' as mat;
@import 'variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-camera-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mona-camera-dialog {
        .mat-dialog-container {
            width: 820px;
            padding: 40px 40px;

            @if $is-dark {
                background-color: mat.get-color-from-palette($primary, 700);
            }
        }

        &__title {
            color: #000;

            @if $is-dark {
                color: #fff;
            }
        }

        &__error {
            border: 1px dashed $mona-error;
        }
    }
}
