@use '@angular/material' as mat;

@import 'containers/procedure-prescriptions/procedure-prescriptions.component.theme';
@import 'containers/procedures/procedures.component.theme';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin prescriptions-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .app-prescriptions {
        &__nav {
            height: 100%;

            .mat-mdc-tab-header {
                border-bottom: none;
                width: 100%;
                margin: 0 auto;
            }

            .mat-tab-body-wrapper {
                height: 100%;
            }

            .mat-mdc-tab {
                opacity: 1;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 500;

                .mat-icon {
                    margin-left: 12px;
                }

                &.mdc-tab--active .mdc-tab__text-label {
                    @if $is-dark {
                        color: mat.get-color-from-palette($primary, A400);
                    } @else {
                        color: mat.get-color-from-palette($primary, 500);
                    }
                }

                .mdc-tab__text-label {
                    @if $is-dark {
                        color: rgba(255, 255, 255, 0.72);
                    } @else {
                        color: #000;
                    }
                }
            }

            &__changes {
                &-asterisk {
                    color: mat.get-color-from-palette($accent);
                }
            }
        }

        &__nav-action-btn {
            .mat-icon {
                margin-right: 0;
                margin-left: 8px;

                &.app-prescriptions__nav-action-btn--drop-down {
                    margin-right: -12px;
                }
            }
        }
    }

    /* ---------------------------------------------------------------------- */
    /* CHILDREN */
    /* ---------------------------------------------------------------------- */
    @include procedure-prescriptions-theme($theme);
    @include procedures-theme($theme);
}
