@use '@angular/material' as mat;

@mixin mona-patient-admission-search-theme($theme) {
    $is-dark: map-get($theme, is-dark);

    .mona-patient-search__column {
        .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
            .mdc-floating-label--float-above {
            @if $is-dark {
                color: rgba(#fff, 0.8);
            }
        }

        .mat-mdc-table .mat-mdc-header-row th,
        .mat-mdc-table .mat-mdc-row td {
            @if $is-dark {
                color: rgba(#fff, 0.8);
            }
        }

        .mat-mdc-table .mat-mdc-row td .stays__item-actions {
            @if $is-dark {
                color: #955afb;
            }
        }

        .patient-admission-empty__description {
            @if $is-dark {
                color: #673ab7;
            }
        }
    }
}
