@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin mona-device-setup-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .app-oobe-screen {
        &__header {
            color: #fff;

            @if $is-dark {
                background-color: #242442;
            } @else {
                background-color: mat.get-color-from-palette($primary);
            }
        }

        &__content {
            background-color: mat.get-color-from-palette($background, card);
            color: mat.get-color-from-palette($foreground, card);
            flex-grow: 1;
        }

        &__footer {
            background-color: mat.get-color-from-palette($background, card);
            color: mat.get-color-from-palette($foreground, card);
            border-top-color: mat.get-color-from-palette($foreground, divider);
            border-top-width: 1px;
            border-top-style: solid;
            padding: 0 64px;
            height: 140px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
        }

        .mat-horizontal-stepper-header-container {
            padding-left: 64px;
            padding-right: 64px;
            margin-bottom: 32px;
            pointer-events: none;
        }

        .mat-horizontal-content-container {
            overflow: auto;
            padding: 0 24px;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

                @if $is-dark {
                    background-color: lighten(#303030, 10);
                } @else {
                    background-color: #f5f5f5;
                }
            }

            &::-webkit-scrollbar {
                background-color: mat.get-color-from-palette($primary);

                &:vertical {
                    width: 6px;
                }

                &:horizontal {
                    height: 0;
                }
            }

            &::-webkit-scrollbar-thumb {
                background-color: mat.get-color-from-palette($primary);
            }
        }

        .mat-horizontal-stepper-content {
            padding-left: 64px;
            padding-right: 64px;
        }
    }
}
