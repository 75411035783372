@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-entry-control-section-confirm-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warning);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .app-entry-control-section-confirm {
        &__btn {
            color: #ffffff;

            &[disabled] {
                background-color: mat.get-color-from-palette($warn) !important;
                color: #ffffff !important;
                opacity: 0.75;
                pointer-events: none;
            }

            .mat-mdc-checkbox {
                pointer-events: none;
                margin-right: auto;
                display: flex;
            }

            .mdc-checkbox__background {
                border-color: #fff !important;
                background-color: transparent !important;
            }

            .mdc-checkbox {
                margin-right: 10px;
            }

            .mdc-label {
                text-transform: uppercase;
                font-weight: 500;
                color: #fff;
            }
        }
    }

    .ui-tab-bar__actions {
        mat-icon {
            margin-left: 10px;
            margin-right: 0;
            order: 1;
            font-size: 24px;
            height: 24px;
            width: 24px;
        }
    }
}
